import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  createContext,
  useContext,
  Suspense,
} from 'react';
import {
  Search,
  BookOpen,
  Loader2,
  AlertCircle,
  Clock,
  ArrowLeft,
  Share2,
  Copy,
  Sun,
  Menu,
  Moon,
  Download,
  Network,
  Lightbulb,
  CheckCircle,
  AlertTriangle,
  ChevronDown,
  ChevronUp,
  ArrowUpCircle,
  Mail,
  Scale,
  ExternalLink,
  MessageCircle,
  Database,
  ShieldCheck,
  FileCheck,
  Code2,
  Paintbrush,
  Component,
  Info,
  Link,
  Minus, 
  Plus, 
  X,
  Brain,
  ZoomIn,
  ZoomOut,
  RotateCcw,
  Puzzle,
  Library,
  Shield,
  Skull,
} from 'lucide-react';
import * as LucideIcons from 'lucide-react';


import { D2 } from '@terrastruct/d2';
import './mermaid.css'; // Import your custom CSS file
import { motion, AnimatePresence } from 'framer-motion';
import * as Dialog from '@radix-ui/react-dialog';
import * as Tooltip from '@radix-ui/react-tooltip';
import { FaLinkedin } from 'react-icons/fa';
import PropTypes from 'prop-types';
import mermaid from 'mermaid';

const WS_KEEPALIVE_INTERVAL = 30000; // 30 seconds
const WS_RESPONSE_TIMEOUT = 45000;    // 45 seconds

// Simplified ImageModal component:
const ImageModal = ({ isOpen, onClose, imageUrl, title, pageNumber, quote }) => {
  const [imageError, setImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setImageError(false);
      setIsLoading(true);
    }
  }, [isOpen]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" />
        <Dialog.Content
          className="fixed inset-4 sm:inset-auto sm:left-1/2 sm:top-1/2 sm:max-w-4xl sm:w-full sm:-translate-x-1/2 sm:-translate-y-1/2 bg-white dark:bg-gray-900 rounded-lg shadow-xl z-50 p-4"
          style={{
            maxHeight: "90vh",
            maxWidth: "95vw",
          }}
          aria-describedby="image-modal-description"
        >
          <div className="h-full flex flex-col">
            <div className="flex justify-between items-center mb-4">
              <Dialog.Title className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                {title} - Page {pageNumber}
              </Dialog.Title>
              <button
                onClick={onClose}
                className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800"
                aria-label="Close modal"
              >
                <X className="w-5 h-5 text-gray-900 dark:text-gray-100" />
              </button>
            </div>

            <div
              className="flex-1 overflow-auto relative bg-gray-100 dark:bg-gray-800 rounded-lg"
              style={{
                maxHeight: "calc(80vh - 6rem)",
                padding: "1rem",
              }}
            >
              <div className="flex items-center justify-center min-h-full">
                {isLoading && !imageError && (
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-100 dark:bg-gray-800">
                    <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                  </div>
                )}

                {imageError ? (
                  <div className="text-gray-600 dark:text-gray-400 flex items-center gap-2">
                    <AlertCircle className="w-5 h-5" />
                    Failed to load image. Please try again later.
                  </div>
                ) : (
                  <img
                    src={`https://legal-oracle-screenshots.s3.ap-southeast-2.amazonaws.com/screenshots/${imageUrl}`}
                    alt={`${title} page ${pageNumber}`}
                    className="max-w-full max-h-full h-auto object-contain"
                    onError={() => {
                      setImageError(true);
                      setIsLoading(false);
                    }}
                    onLoad={() => setIsLoading(false)}
                    style={{ display: isLoading ? 'none' : 'block' }}
                  />
                )}
              </div>
            </div>

            {/* Screen Reader Description and Quote */}
            <div id="image-modal-description" className="sr-only">
              Image viewer for {title} page {pageNumber}. {quote && `Showing quote: ${quote}`}
            </div>

            {quote && (
              <div className="mt-4 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
                <p className="text-sm text-gray-700 dark:text-gray-300 italic">
                  "{quote}"
                </p>
              </div>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};


const ANZSOCBadge = ({ code }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <Tooltip.Provider>
      <Tooltip.Root open={isOpen}>
        <Tooltip.Trigger asChild>
          <button 
            onClick={handleClick}
            className="inline-flex items-center gap-1 px-1.5 py-0.5 text-xs 
              bg-gray-50 hover:bg-gray-100 
              dark:bg-gray-800 dark:hover:bg-gray-700
              text-gray-600 dark:text-gray-400
              rounded border border-gray-200 dark:border-gray-700
              transition-colors"
          >
            <Scale className="h-3 w-3" />
            {code.code}
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content 
            className="z-50 max-w-xs bg-white dark:bg-gray-800 
              rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 
              p-4 animate-in fade-in-0 zoom-in-95"
            sideOffset={5}
          >
            <div className="space-y-2">
              <div className="text-sm font-medium text-gray-900 dark:text-gray-100">
                {code.code} - {code.description}
              </div>
              <div className="text-xs space-y-1 text-gray-600 dark:text-gray-400">
                <div>{code.division}</div>
                <div>{code.subdivision}</div>
              </div>
            </div>
            <Tooltip.Arrow className="fill-white dark:fill-gray-800" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};




const ThoughtModal = ({ isOpen, onClose, thoughts }) => {
  if (!thoughts || thoughts.length === 0) return null;

  // Helper function to format content with proper list styling
  const formatContent = (content) => {
    const lines = content.split('\n');
    let isInList = false;
    
    return lines.map((line, index) => {
      // Handle stage headers
      if (line.startsWith('STAGE')) {
        return (
          <h3 key={index} className="text-lg font-semibold text-blue-600 dark:text-blue-400 mt-6 first:mt-0">
            {line}
          </h3>
        );
      }
      
      // Handle numbered lists
      const numberedMatch = line.match(/^\d+\.\s(.+)/);
      if (numberedMatch) {
        isInList = true;
        return (
          <div key={index} className="flex items-start gap-3 ml-4 mt-2">
            <span className="text-blue-500 dark:text-blue-400 font-medium min-w-[1.5rem]">
              {line.split('.')[0]}.
            </span>
            <span className="text-gray-700 dark:text-gray-300">
              {numberedMatch[1]}
            </span>
          </div>
        );
      }
      
      // Handle bullet points
      if (line.trim().startsWith('-')) {
        isInList = true;
        return (
          <div key={index} className="flex items-start gap-3 ml-6 mt-2">
            <span className="text-blue-500 dark:text-blue-400 mt-1">•</span>
            <span className="text-gray-700 dark:text-gray-300">
              {line.trim().substring(1).trim()}
            </span>
          </div>
        );
      }
      
      // Handle regular text
      if (line.trim()) {
        const className = isInList ? "mt-2 ml-6" : "mt-2";
        isInList = false;
        return (
          <p key={index} className={`text-gray-700 dark:text-gray-300 ${className}`}>
            {line}
          </p>
        );
      }
      
      // Handle empty lines
      return <div key={index} className="h-2" />;
    });
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" />
        <Dialog.Content className="fixed inset-4 sm:inset-auto sm:left-1/2 sm:top-1/2 sm:max-w-3xl sm:w-full sm:-translate-x-1/2 sm:-translate-y-1/2 bg-white dark:bg-gray-900 rounded-lg shadow-xl z-50 p-6">
          <div className="flex justify-between items-center mb-4">
            <Dialog.Title className="text-xl font-bold text-gray-900 dark:text-gray-100 flex items-center gap-2">
              <Brain className="h-6 w-6 text-blue-500" />
              Analysis Process
            </Dialog.Title>
            <button
              onClick={onClose}
              className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-500 dark:text-gray-400 transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="space-y-6 max-h-[70vh] overflow-y-auto">
            {thoughts.map((thought, index) => (
              <div 
                key={index}
                className="bg-gray-50 dark:bg-gray-800 rounded-lg p-4 border border-gray-200 dark:border-gray-700"
              >
                {formatContent(thought)}
              </div>
            ))}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};



const MermaidConfig = () => {
  const { theme } = useTheme();
  
	useEffect(() => {
	  mermaid.initialize({
		startOnLoad: true,
		theme: theme === 'dark' ? 'dark' : 'base',
		securityLevel: 'loose',
		fontFamily: 'inherit',
		flowchart: {
		  htmlLabels: true,
		  padding: 20,
		  curve: 'basis',
		},
		sequence: {
		  mirrorActors: false,
		},
		gantt: {
		  axisFormatter: [],
		},
		class: {},
		// Enable zoom and pan
		zoom: {
		  enabled: true,
		  scaleExtent: [0.5, 3],
		  useTransition: true,
		},
		maxTextSize: 100000,
	  });
	}, [theme]);

  return null;
};

// Create Theme and FontSize Context
const ThemeContext = createContext();
const FontSizeContext = createContext();

// Custom Hooks
const useTheme = () => useContext(ThemeContext);
const useFontSize = () => useContext(FontSizeContext);

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-center py-8">
          <AlertCircle className="h-8 w-8 text-red-600 mx-auto mb-4" aria-hidden="true" />
          <p className="text-red-700">Something went wrong. Please try again later.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

// Utility function to sanitize inputs
const sanitizeInput = (input) => {
  const div = document.createElement('div');
  div.textContent = input;
  return div.innerHTML;
};

// Constants
const JURISDICTIONS = [
  {
    name: 'South Australia',
    code: 'SA',
    available: true,
    acts: 569,
    regulations: 469,
    questions: [
      "What are the tenant's rights when ending a rental agreement?",
      "How much notice must a landlord give before raising rent?",
      "What constitutes workplace harassment?",
      "What are the legal requirements for forming a business partnership?",
      "How can I file a consumer complaint against a business?",
      "What are the penalties for driving without a valid license?",
      "How does parental leave work under local labor laws?",
      "What are the building permit requirements for home renovations?",
      "How are inheritance disputes resolved?",
      "What are the privacy obligations for businesses collecting customer data?",
      "What steps must be taken to challenge a speeding ticket?",
      "What is considered unfair dismissal in employment law?",
      "What are the age restrictions for alcohol purchase and consumption?",
      "What is the process for filing a police report for stolen property?",
      "What are the environmental protection laws for residential properties?",
      "What legal protections exist for small businesses in disputes?",
      "What are the regulations around public protests or demonstrations?",
      "How do local laws regulate pet ownership and animal welfare?",
      "What are the legal requirements for starting a home-based business?",
      "What are the penalties for illegal dumping or littering?",
      "How can I request access to public records from the government?",
      "What legal obligations do employers have regarding workplace safety?",
      "What are the rules for installing surveillance cameras at home?",
      "What are the restrictions on advertising and signage for businesses?",
      "How do local councils regulate noise complaints from neighbors?"
    ],
  },
  {
    name: 'Victoria',
    code: 'VIC',
    available: true,
    acts: 777,
    regulations: 408,
    questions: [
      "What are the rights of tenants when a property is sold?",
      "How do I contest a parking fine in my local area?",
      "What are the child custody arrangements after a divorce?",
      "How is workplace discrimination defined under labor law?",
      "What are the legal requirements for hosting public events?",
      "How can I apply for a restraining order?",
      "What are the obligations for landlords regarding home repairs?",
      "How are consumer warranties enforced for defective products?",
      "What are the limits on political campaign advertising?",
      "How are penalties for breaches of environmental regulations determined?",
      "What are the rules for setting up a charity or nonprofit organization?",
      "How do I report fraud or financial crime?",
      "What legal protections exist for customers purchasing online goods?",
      "What are the zoning regulations for building commercial properties?",
      "How is domestic violence legally defined and prosecuted?",
      "What are the rights of employees under minimum wage laws?",
      "What are the rules for registering a new vehicle?",
      "What are the obligations of companies under data breach notification laws?",
      "How do I challenge a court decision in a civil lawsuit?",
      "What legal requirements apply to setting up an import/export business?",
      "What is considered trespassing on private property?",
      "What are the reporting requirements for financial institutions?",
      "What are the penalties for unauthorized building extensions?",
      "How are disputes over shared property boundaries resolved?",
      "What rules apply to advertising alcohol products?"
    ],
  },
  {
    name: 'Commonwealth (Federal)',
    code: 'CWTH',
    available: true,
    acts: 4636,
    regulations: 0,
    questions: [
      "How is tax fraud investigated by federal authorities?",
      "What are the regulations for international trade and imports?",
      "What are the consumer rights when dealing with faulty electronics?",
      "How is anti-money laundering enforced at the federal level?",
      "What are the requirements for Australian citizenship?",
      "What are the privacy rights under the national Privacy Act?",
      "How are international business contracts legally enforced?",
      "What rules apply to cross-border banking services?",
      "How can I apply for federal government grants for businesses?",
      "What legal protections exist for whistleblowers in government agencies?",
      "What are the rules for foreign investments in local companies?",
      "How does copyright law protect creative works in Australia?",
      "What are the laws governing national security and surveillance?",
      "How are disputes over intellectual property resolved?",
      "What federal standards apply to aviation and airline safety?",
      "What are the penalties for violating customs regulations?",
      "How are family law disputes involving multiple states resolved?",
      "What are the rules for exporting agricultural products?",
      "What are the protections for employees under federal labor law?",
      "How do national regulations address digital privacy concerns?",
      "What legal standards apply to medical product testing and approval?",
      "What are the tax reporting obligations for online businesses?",
      "How are cross-border child custody disputes resolved?",
      "What are the penalties for violations of anti-discrimination laws?",
      "What federal legal protections exist for online consumer fraud?"
    ],
  }
];

const MAX_HISTORY = 10;
const MAX_SUGGESTIONS = 5;

const TopNavigation = ({ setShowAbout, setShowDisclaimer, setShowSafety }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const { theme, toggleTheme } = useTheme();
  const { fontSize, toggleFontSize } = useFontSize();

  // Scroll to query box
const scrollToQuery = () => {
  const queryBox = document.getElementById('question-input') || 
                  document.getElementById('concept-input');
  if (queryBox) {
    const offset = 350; // Adjust this value based on your header height
    const elementPosition = queryBox.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - offset;
    
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
    
    // Small delay before focus to ensure smooth scroll completes
    setTimeout(() => {
      queryBox.focus();
    }, 100);
  }
};

  return (
    <nav className="bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-700">
      <div className="max-w-4xl mx-auto px-4">
        <div className="flex items-center justify-between h-14">
          {/* Left side nav items */}
          <div className="hidden md:flex items-center space-x-6">
            <button 
              onClick={() => setShowAbout(true)}
              className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100"
            >
              About
            </button>
            <button 
              onClick={() => setShowSafety(true)}
              className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100"
            >
              How it Works
            </button>
            <button 
              onClick={() => setShowDisclaimer(true)}
              className="text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300 font-medium flex items-center gap-1"
            >
              <AlertTriangle className="h-4 w-4" />
              <span>Disclaimer</span>
            </button>
          </div>

          {/* Right side */}
          <div className="flex items-center space-x-2 ml-auto">
            {/* Theme and Font Size Controls */}
            <div className="hidden md:flex items-center space-x-2">
              <button
                onClick={toggleFontSize}
                className="p-2 rounded-md bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 transition-colors"
                aria-label="Toggle Font Size"
              >
                <span className="text-gray-700 dark:text-gray-300">
                  {fontSize === 'normal' ? 'A+' : 'A-'}
                </span>
              </button>
              <button
                onClick={toggleTheme}
                className="p-2 rounded-md bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 transition-colors"
                aria-label="Toggle Theme"
              >
                {theme === 'dark' ? (
                  <Sun className="h-5 w-5 text-yellow-500" />
                ) : (
                  <Moon className="h-5 w-5 text-gray-700" />
                )}
              </button>
            </div>

            {/* Auth buttons */}
            <div className="hidden md:flex items-center space-x-2">
              <button 
                onClick={() => setShowComingSoon(true)}
                className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100"
              >
                Sign in
              </button>
              <button 
                onClick={scrollToQuery}
                className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg"
              >
                Get Started
              </button>
            </div>

            {/* Mobile menu button */}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden p-2 text-gray-600 dark:text-gray-400"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>

{/* Mobile menu */}
{isMenuOpen && (
  <div className="md:hidden py-4 border-t border-gray-200 dark:border-gray-700">
    <div className="flex flex-col space-y-4">
      <button 
        onClick={() => {
          setShowAbout(true);
          setIsMenuOpen(false);
        }}
        className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 px-2 py-1"
      >
        About
      </button>
      <button 
        onClick={() => {
          setShowSafety(true);
          setIsMenuOpen(false);
        }}
        className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 px-2 py-1"
      >
        How it Works
      </button>
      <button 
        onClick={() => {
          setShowDisclaimer(true);
          setIsMenuOpen(false);
        }}
        className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 px-2 py-1"
      >
        Disclaimer
      </button>
      <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
        <button 
          onClick={() => {
            setShowComingSoon(true);
            setIsMenuOpen(false);
          }}
          className="w-full text-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 px-2 py-1"
        >
          Sign in
        </button>
        <button 
          onClick={() => {
            scrollToQuery();
            setIsMenuOpen(false);
          }}
          className="w-full mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          Get Started
        </button>
      </div>
            {/* Add theme and font controls to mobile menu */}
            <div className="flex items-center justify-center gap-2 pt-4 border-t border-gray-200 dark:border-gray-700">
              <button
                onClick={toggleFontSize}
                className="p-2 rounded-md bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700"
                aria-label="Toggle Font Size"
              >
                <span className="text-gray-700 dark:text-gray-300">
                  {fontSize === 'normal' ? 'A+' : 'A-'}
                </span>
              </button>
              <button
                onClick={toggleTheme}
                className="p-2 rounded-md bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700"
                aria-label="Toggle Theme"
              >
                {theme === 'dark' ? (
                  <Sun className="h-5 w-5 text-yellow-500" />
                ) : (
                  <Moon className="h-5 w-5 text-gray-700" />
                )}
              </button>
            </div>	  
    </div>
  </div>
)}
      </div>

      {/* Coming Soon Dialog */}
      <Dialog.Root open={showComingSoon} onOpenChange={setShowComingSoon}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" />
          <Dialog.Content className="fixed top-1/2 left-1/2 max-w-sm w-[90vw] -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white dark:bg-gray-900 p-6 shadow-xl z-50 border border-gray-200 dark:border-gray-700">
            <div className="flex flex-col items-center gap-4">
              <div className="p-3 bg-blue-100 dark:bg-blue-900/30 rounded-full">
                <Clock className="h-6 w-6 text-blue-600 dark:text-blue-400" />
              </div>
              <div className="text-center">
                <Dialog.Title className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                  Coming Soon
                </Dialog.Title>
                <Dialog.Description className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                  User accounts are coming soon! This feature will allow you to save your search history and preferences.
                </Dialog.Description>
              </div>
              <button
                onClick={() => setShowComingSoon(false)}
                className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Got it
              </button>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </nav>
  );
};









// DisclaimerModal Component
const DisclaimerModal = ({ isOpen, onClose }) => {
  const [isBot, setIsBot] = useState(false);
  
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsBot(/bot|googlebot|crawler|spider|robot|crawling/i.test(userAgent));
  }, []);

  if (isBot) return null;

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[500px]
          transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white dark:bg-gray-900 border
          border-gray-300 dark:border-gray-700 p-6 shadow-lg z-50 overflow-y-auto focus:outline-none"
          aria-describedby="disclaimer-description"
        >
          <div className="text-xl font-bold text-red-600 dark:text-red-500 flex items-center gap-2 mb-4">
            <AlertCircle className="h-5 w-5" aria-hidden="true" />
            Important Notice
            <span className="bg-blue-600 text-white text-sm px-2 py-0.5 rounded-lg ml-auto">
              BETA
            </span>
          </div>

          <div className="space-y-4">
            <div className="bg-yellow-100 dark:bg-yellow-900/30 border border-yellow-300 dark:border-yellow-700/50 rounded-lg p-4">
              <p className="text-yellow-900 dark:text-yellow-200 text-sm" id="disclaimer-description">
                This is an AI-powered tool currently in BETA and FREE to use. It provides information about legislation but is not a substitute for professional legal advice.
              </p>
            </div>

            <div className="space-y-2">
              <h3 className="font-semibold text-gray-800 dark:text-gray-200">
                By using this service, you acknowledge:
              </h3>
              <ul className="space-y-2 text-sm text-gray-800 dark:text-gray-300">
                <li className="flex items-start gap-2">
                  <span className="text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true">
                    •
                  </span>
                  This is a research tool only, not a source of legal advice
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true">
                    •
                  </span>
                  Information may be incomplete or outdated
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true">
                    •
                  </span>
                  You should consult qualified legal professionals for specific advice
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true">
                    •
                  </span>
                  No responsibility is taken for decisions made based on this information
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-6 space-y-4">
            <button
              onClick={onClose}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              I Understand and Accept
            </button>
            <p className="text-xs text-gray-700 dark:text-gray-400 text-center">
              Created by Hamish Cameron
            </p>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};


DisclaimerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const SystemProtections = () => {
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">How Legal Oracle Works</h2>
        <Dialog.Close className="p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300">
          <X className="h-5 w-5" />
        </Dialog.Close>
      </div>
      
      <div className="grid gap-6">
        {/* Search Process */}
        <div className="flex gap-4">
          <div className="mt-1">
            <Search className="h-6 w-6 text-blue-600 dark:text-blue-400" />
          </div>
          <div>
            <h3 className="font-semibold text-lg text-gray-900 dark:text-gray-100">Search Process</h3>
            <ul className="mt-2 space-y-2 text-gray-700 dark:text-gray-300">
              <li>• Uses semantic search to find relevant legislation sections</li>
              <li>• May not find all relevant sections due to search limitations</li>
              <li>• Results depend on how questions are phrased</li>
              <li>• Focused on specific jurisdictions to improve accuracy</li>
            </ul>
          </div>
        </div>

        {/* Response Generation */}
        <div className="flex gap-4">
          <div className="mt-1">
            <MessageCircle className="h-6 w-6 text-blue-600 dark:text-blue-400" />
          </div>
          <div>
            <h3 className="font-semibold text-lg text-gray-900 dark:text-gray-100">Response Generation</h3>
            <ul className="mt-2 space-y-2 text-gray-700 dark:text-gray-300">
              <li>• AI attempts to use only provided legislative excerpts</li>
              <li>• Has guardrails but may occasionally stray from sources</li>
              <li>• Provides links to original legislation for verification</li>
              <li>• Screenshots help verify quoted text</li>
            </ul>
          </div>
        </div>

        {/* System Limitations */}
        <div className="flex gap-4">
          <div className="mt-1">
            <AlertCircle className="h-6 w-6 text-blue-600 dark:text-blue-400" />
          </div>
          <div>
            <h3 className="font-semibold text-lg text-gray-900 dark:text-gray-100">Known Limitations</h3>
            <ul className="mt-2 space-y-2 text-gray-700 dark:text-gray-300">
              <li>• May miss relevant sections due to search limitations</li>
              <li>• Understanding varies based on question phrasing</li>
              <li>• Legislative database updates may have delays</li>
              <li>• Complex legal scenarios may be oversimplified</li>
            </ul>
          </div>
        </div>

        {/* Data Collection Notice */}
        <div className="flex gap-4 bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg border border-blue-200 dark:border-blue-800">
          <div className="mt-1">
            <Database className="h-6 w-6 text-blue-600 dark:text-blue-400" />
          </div>
          <div>
            <h3 className="font-semibold text-lg text-blue-800 dark:text-blue-300">Data Collection Notice</h3>
            <p className="mt-2 text-blue-700 dark:text-blue-200">
              For quality assurance and system improvement, we log all queries, responses, and IP addresses. 
              All data is stored securely in Australia in compliance with applicable privacy laws.
            </p>
          </div>
        </div>

        {/* Important Notice */}
        <div className="flex gap-4 bg-amber-50 dark:bg-amber-900/20 p-4 rounded-lg border border-amber-200 dark:border-amber-800">
          <div className="mt-1">
            <AlertTriangle className="h-6 w-6 text-amber-600 dark:text-amber-400" />
          </div>
          <div>
            <h3 className="font-semibold text-lg text-amber-800 dark:text-amber-300">Important Notice</h3>
            <p className="mt-2 text-amber-700 dark:text-amber-200">
              This is an experimental research tool that attempts to find and present relevant legislation. 
              Results may be incomplete or imperfect. Always verify information through official sources 
              and consult qualified legal professionals for advice. Do not rely solely on this system 
              for legal research or decision-making.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

// AboutModal Component
// AboutModal Component
const AboutModal = ({ isOpen, onClose }) => {
  const [lastUpdated, setLastUpdated] = useState('Loading...'); // Default loading state

useEffect(() => {
  fetch('https://legal-oracle-screenshots.s3.ap-southeast-2.amazonaws.com/last_updated.txt.txt')
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to fetch last updated date');
      }
      return response.text();
    })
    .then(date => {

		//CHECK REGEX - SHOULD BE DD/MM/YYYY OR YYYY-MM-DD BASED ON FILE CONTENT
      if (/^\d{2}\/\d{2}\/\d{4}$/.test(date.trim())) { //**CRITICAL LINE**
        const [day, month, year] = date.trim().split('/'); // Split by '/'
        const formattedDate = `${day}/${month}/${year}`; // No change
        setLastUpdated(formattedDate);
      } else {
      }
    })
    .catch(error => {
    });
}, []);

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[600px]
          transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white dark:bg-gray-900 border
          border-gray-300 dark:border-gray-700 p-6 shadow-lg z-50 overflow-y-auto focus:outline-none"
        >
          {/* Close button in top right */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
          >
            <X className="h-5 w-5" />
          </button>

          <div className="space-y-8">
            {/* Title and Beta Badge */}
            <div className="flex items-center gap-3">
              <BookOpen className="h-8 w-8 text-blue-500" aria-hidden="true" />
              <div>
                <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                  Legal Oracle
                </h2>
                <div className="flex items-center gap-2 mt-1">
                  <span className="bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200 text-xs font-medium px-2.5 py-0.5 rounded-full">BETA</span>
                  <span className="text-sm text-gray-500">Free Research Tool</span>
                </div>
              </div>
            </div>

            {/* Overview Section */}
            <section>
              <h3 className="text-lg font-semibold mb-3 text-gray-900 dark:text-gray-100">About</h3>
              <p className="text-gray-600 dark:text-gray-400 leading-relaxed mb-4">
                Legal Oracle is an AI-powered research assistant designed to help Australians understand legislation 
                across South Australia, Victoria, and Commonwealth jurisdictions. Currently in beta, it's free to use 
                and aims to make legal research more accessible to everyone.
              </p>
              <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg border border-blue-100 dark:border-blue-800">
                <p className="text-sm text-blue-800 dark:text-blue-200">
                  As a beta service, we're continuously improving accuracy and features. Your feedback helps us enhance the service.
                </p>
              </div>
            </section>

            {/* Coverage Section */}
            <section>
              <h3 className="text-lg font-semibold mb-3 text-gray-900 dark:text-gray-100">Coverage</h3>
              <div className="space-y-3">
                <div className="flex items-start gap-2">
                  <div className="p-2 bg-green-100 dark:bg-green-900/30 rounded-lg">
                    <FileCheck className="h-5 w-5 text-green-600 dark:text-green-400" />
                  </div>
                  <div>
                    <a 
                      href="https://www.legislation.sa.gov.au"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group"
                    >
                      <p className="font-medium text-gray-900 dark:text-gray-100 flex items-center gap-1 hover:text-blue-600 dark:hover:text-blue-400">
                        South Australia
                        <ExternalLink className="h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                      </p>
                      <p className="text-sm text-gray-600 dark:text-gray-400">569 Acts and 469 Regulations</p>
                    </a>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <div className="p-2 bg-green-100 dark:bg-green-900/30 rounded-lg">
                    <FileCheck className="h-5 w-5 text-green-600 dark:text-green-400" />
                  </div>
                  <div>
                    <a 
                      href="https://www.legislation.vic.gov.au"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group"
                    >
                      <p className="font-medium text-gray-900 dark:text-gray-100 flex items-center gap-1 hover:text-blue-600 dark:hover:text-blue-400">
                        Victoria
                        <ExternalLink className="h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                      </p>
                      <p className="text-sm text-gray-600 dark:text-gray-400">777 Acts and 408 Regulations</p>
                    </a>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <div className="p-2 bg-green-100 dark:bg-green-900/30 rounded-lg">
                    <FileCheck className="h-5 w-5 text-green-600 dark:text-green-400" />
                  </div>
                  <div>
                    <a 
                      href="https://www.legislation.gov.au"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group"
                    >
                      <p className="font-medium text-gray-900 dark:text-gray-100 flex items-center gap-1 hover:text-blue-600 dark:hover:text-blue-400">
                        Commonwealth
                        <ExternalLink className="h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                      </p>
                      <p className="text-sm text-gray-600 dark:text-gray-400">4,636 Acts</p>
                    </a>
                  </div>
                </div>
              </div>
            </section>

            {/* Database Currency Section */}
            <section className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4 border border-gray-200 dark:border-gray-700">
              <div className="flex items-start gap-2">
                <div className="p-2 bg-blue-100 dark:bg-blue-900/30 rounded-lg">
                  <Clock className="h-5 w-5 text-blue-600 dark:text-blue-400" />
                </div>
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-gray-100">Database Currency</h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                    Legislation database last refreshed: {' '}
                    <span className="font-medium text-blue-600 dark:text-blue-400">
                      {lastUpdated}
                    </span>
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
                    We regularly update our database to ensure accuracy and completeness. 
                    Always verify current legislation through official sources.
                  </p>
                </div>
              </div>
            </section>

            {/* Features Section */}
            <section>
              <h3 className="text-lg font-semibold mb-3 text-gray-900 dark:text-gray-100">Key Features</h3>
              <div className="grid gap-3">
                <div className="flex items-start gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500 mt-1" />
                  <span className="text-gray-600 dark:text-gray-400">Cross-jurisdictional search across SA, VIC, and Commonwealth legislation</span>
                </div>
                <div className="flex items-start gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500 mt-1" />
                  <span className="text-gray-600 dark:text-gray-400">Visual concept maps showing relationships between different pieces of legislation</span>
                </div>
                <div className="flex items-start gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500 mt-1" />
                  <span className="text-gray-600 dark:text-gray-400">Plain English explanations with direct citations to source legislation</span>
                </div>
                <div className="flex items-start gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500 mt-1" />
                  <span className="text-gray-600 dark:text-gray-400">Monthly database updates to maintain accuracy</span>
                </div>
              </div>
            </section>

            {/* Important Notice */}
            <section className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-lg border border-yellow-200 dark:border-yellow-800">
              <h3 className="text-lg font-semibold mb-2 text-yellow-800 dark:text-yellow-200">Important Notice</h3>
              <p className="text-yellow-800 dark:text-yellow-200 text-sm leading-relaxed">
                Legal Oracle is a research tool only and should not be relied upon as legal advice. Always verify 
                information through official sources and consult qualified legal professionals for specific advice.
              </p>
            </section>

            {/* Contact */}
            <section>
              <h3 className="text-lg font-semibold mb-3 text-gray-900 dark:text-gray-100">Contact</h3>
              <a
                href="mailto:contact@legaloracle.ai"
                className="flex items-center gap-2 text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
              >
                <Mail className="h-5 w-5" />
                <span>contact@legaloracle.ai</span>
              </a>
            </section>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};





// StatsDisplay Component
const StatsDisplay = React.memo(({ selectedJurisdictions }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const combinedStats = useMemo(() => {
    const totals = {
      Acts: 0,
      Regulations: 0
    };
    
    // Calculate totals from all jurisdictions
    JURISDICTIONS.forEach(jurisdiction => {
      if (jurisdiction.available) {
        totals.Acts += jurisdiction.acts;
        totals.Regulations += jurisdiction.regulations;
      }
    });
    
    return Object.entries(totals)
      .filter(([_, count]) => count > 0)
      .map(([type, count]) => ({ count, type }));
  }, []); // Empty dependency array since we're using all jurisdictions

  useEffect(() => {
    if (combinedStats.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prev) => (prev + 1) % combinedStats.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [combinedStats.length]);

  // Guard against invalid index
  const safeIndex = currentIndex >= combinedStats.length ? 0 : currentIndex;
  const currentStat = combinedStats[safeIndex];

  return (
    <p className="text-sm text-gray-600 dark:text-gray-400">
      Searching across{' '}
      <span className="relative h-6 inline-flex align-baseline overflow-hidden">
        <AnimatePresence mode="wait">
          <motion.span
            key={`${currentStat.type}-${currentStat.count}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="inline-block whitespace-nowrap"
            aria-live="polite"
          >
            {currentStat.count.toLocaleString()} {currentStat.type}
          </motion.span>
        </AnimatePresence>
      </span>
    </p>
  );
});

StatsDisplay.propTypes = {
  selectedJurisdictions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const MermaidDiagram = ({ diagram }) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [zoom, setZoom] = useState(1.5);
  const [isPanning, setIsPanning] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [lastPanPosition, setLastPanPosition] = useState({ x: 0, y: 0 });
  const MIN_ZOOM = 0.5;
  const MAX_ZOOM = 3;
  const ZOOM_STEP = 0.1;

  useEffect(() => {
    if (contentRef.current && diagram) {
      try {
        contentRef.current.innerHTML = '';
        const diagramId = `mermaid-diagram-${Date.now()}`;
        mermaid
          .render(diagramId, diagram)
          .then(({ svg }) => {
            if (contentRef.current) {
              contentRef.current.innerHTML = svg;

              // Reset position when diagram changes
              setPosition({ x: 0, y: 0 });
              setZoom(1);
            }
          })
          .catch((error) => {
            if (contentRef.current) {
              contentRef.current.innerHTML =
                '<p class="text-red-500">Failed to render diagram</p>';
            }
          });
      } catch (error) {
        if (contentRef.current) {
          contentRef.current.innerHTML =
            '<p class="text-red-500">Failed to render diagram</p>';
        }
      }
    }
  }, [diagram]);

  const handlePanStart = (e) => {
    e.preventDefault();
    setIsPanning(true);
    const clientX = e.type === 'touchstart' ? e.touches[0].clientX : e.clientX;
    const clientY = e.type === 'touchstart' ? e.touches[0].clientY : e.clientY;
    setLastPanPosition({
      x: clientX - position.x,
      y: clientY - position.y,
    });
  };

  const handlePanMove = (e) => {
    if (!isPanning) return;
    const clientX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
    const clientY = e.type === 'touchmove' ? e.touches[0].clientY : e.clientY;
    const newX = clientX - lastPanPosition.x;
    const newY = clientY - lastPanPosition.y;
    setPosition({ x: newX, y: newY });
  };

  const handlePanEnd = () => {
    setIsPanning(false);
  };

  const handleWheel = (e) => {
    e.preventDefault();
    const delta = e.deltaY > 0 ? -ZOOM_STEP : ZOOM_STEP;
    setZoom((prevZoom) => {
      const newZoom = Math.min(Math.max(prevZoom + delta, MIN_ZOOM), MAX_ZOOM);
      return newZoom;
    });
  };

  const handleZoomIn = () => {
    setZoom((prev) => Math.min(prev + ZOOM_STEP, MAX_ZOOM));
  };

  const handleZoomOut = () => {
    setZoom((prev) => Math.max(prev - ZOOM_STEP, MIN_ZOOM));
  };

  const handleZoomReset = () => {
    setZoom(1);
    setPosition({ x: 0, y: 0 });
  };

  // Add event listeners for touch devices
  useEffect(() => {
    const content = contentRef.current;
    if (!content) return;

    content.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      content.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <div className="overflow-hidden">
      <div className="min-w-full">
        <div className="bg-gray-200 dark:bg-gray-800/50 rounded-lg border border-gray-300 dark:border-gray-700 p-4">
          {/* Controls */}
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-4">
            <div className="text-sm text-gray-600 dark:text-gray-400">
              This diagram shows the relationships between relevant legislation and regulations.
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={handleZoomOut}
                disabled={zoom <= MIN_ZOOM}
                className="p-2 rounded-lg bg-white dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors border border-gray-300 dark:border-gray-600 disabled:opacity-50"
                aria-label="Zoom Out"
              >
                <Minus className="w-4 h-4" />
              </button>
              <button
                onClick={handleZoomReset}
                className="px-3 py-2 rounded-lg bg-white dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors border border-gray-300 dark:border-gray-600"
              >
                <span className="text-sm font-medium">{Math.round(zoom * 100)}%</span>
              </button>
              <button
                onClick={handleZoomIn}
                disabled={zoom >= MAX_ZOOM}
                className="p-2 rounded-lg bg-white dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors border border-gray-300 dark:border-gray-600 disabled:opacity-50"
                aria-label="Zoom In"
              >
                <Plus className="w-4 h-4" />
              </button>
            </div>
          </div>

          {/* Diagram Viewport */}
          <div
            ref={containerRef}
            className="relative overflow-hidden w-full"
            style={{ height: '60vh' }}
          >
            {/* Draggable Content */}
            <div
              ref={contentRef}
              className="absolute inset-0 cursor-grab active:cursor-grabbing touch-pan-y touch-pan-x"
              style={{
                transform: `translate(${position.x}px, ${position.y}px) scale(${zoom})`,
                transformOrigin: 'center',
                transition: isPanning ? 'none' : 'transform 0.1s ease',
              }}
              onMouseDown={handlePanStart}
              onMouseMove={handlePanMove}
              onMouseUp={handlePanEnd}
              onMouseLeave={handlePanEnd}
              onTouchStart={handlePanStart}
              onTouchMove={handlePanMove}
              onTouchEnd={handlePanEnd}
            />
          </div>

          {/* Tip */}
          <div className="mt-4 text-xs text-gray-500 dark:text-gray-400 flex items-center gap-1">
            <Info className="w-4 h-4" />
            <span>
              Tip: Use pinch-to-zoom and drag gestures on touch devices, or scroll and drag with your mouse.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

MermaidDiagram.propTypes = {
  diagram: PropTypes.string.isRequired,
};


// ExampleQuestionsGrid Component
const ExampleQuestionsGrid = React.memo(({ onQuestionClick, selectedJurisdiction }) => {
  const [randomExamples, setRandomExamples] = useState([]);

  useEffect(() => {
    const getRandomQuestions = () => {
      const jurisdiction = JURISDICTIONS.find(j => j.code === selectedJurisdiction);
      const questions = jurisdiction?.questions || [];
      const shuffled = [...questions].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 5);
    };

    setRandomExamples(getRandomQuestions());

    const interval = setInterval(() => {
      setRandomExamples(getRandomQuestions());
    }, 10000);

    return () => clearInterval(interval);
  }, [selectedJurisdiction]);

  return (
    <div className="space-y-8" aria-label="Example Questions">
      <div className="text-center space-y-4">
        <p className="text-sm text-gray-600 dark:text-gray-400">Popular questions:</p>
        <div className="flex flex-wrap gap-2 justify-center">
          {randomExamples.map((example, index) => (
            <button
              key={index}
              onClick={() => onQuestionClick(example)}
              className="px-4 py-2 bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-full text-sm text-gray-700 dark:text-gray-300 transition-colors border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label={`Search for: ${example}`}
            >
              {example}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
});


ExampleQuestionsGrid.propTypes = {
  onQuestionClick: PropTypes.func.isRequired,
};

// Footer Component
const Footer = React.memo(() => (
  <footer className="mt-12 pt-6 border-t border-gray-300 dark:border-gray-800">
    <div className="flex flex-col items-center justify-center gap-6 text-sm text-gray-600 dark:text-gray-400">
      <div className="flex flex-col items-center gap-2">
        <span>Created by Hamish Cameron</span>
        <span>© {new Date().getFullYear()} All rights reserved.</span>
      </div>
      
      <div className="flex items-center gap-4">
        <a
          href="https://www.linkedin.com/in/hamishcameron/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-1 text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300 transition-colors"
          title="Connect on LinkedIn"
          aria-label="Connect on LinkedIn"
        >
          <FaLinkedin className="w-6 h-6" aria-hidden="true" />
        </a>
        <a
          href="mailto:contact@legaloracle.ai"
          className="flex items-center gap-1 text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300 transition-colors"
          title="Email Us"
          aria-label="Email Us"
        >
          <Mail className="w-6 h-6" aria-hidden="true" />
        </a>
      </div>
    </div>
  </footer>
));

Footer.propTypes = {
  setShowDisclaimer: PropTypes.func.isRequired
};



// (Continue with the rest of the components: SectionPlaceholder, FormattedAnswer, and the main App component)

const FeedbackPanel = ({ type, queryText, responseText, wsRef }) => {
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [error, setError] = useState(null);
  const [showComment, setShowComment] = useState(false);
  const [hoverRating, setHoverRating] = useState(null);

  const handleSubmit = () => {
    if (!rating) return;
    
    try {
      if (!wsRef?.current) {
        throw new Error('WebSocket connection not available');
      }

      if (wsRef.current.readyState !== WebSocket.OPEN) {
        throw new Error('WebSocket connection not open');
      }

      const feedback = {
        action: 'feedback',
        body: {
          type: type || 'query',
          rating: rating,
          comment: comment.trim() || null,
          query_text: queryText || '',
          response_text: responseText || '',
          timestamp: new Date().toISOString()
        }
      };

      wsRef.current.send(JSON.stringify(feedback));
      setIsSubmitted(true);
      setError(null);
      
      setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    } catch (error) {
      setError('Unable to submit feedback. Please try again later.');
    }
  };

  if (!isVisible) return null;

  if (isSubmitted) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="flex items-center justify-center gap-2 py-4 bg-green-50 dark:bg-green-900/30 text-green-700 dark:text-green-300 font-medium rounded-lg border border-green-200 dark:border-green-800 shadow-lg"
      >
        <CheckCircle className="w-5 h-5 text-green-500" />
        <span>Thanks for helping improve Legal Oracle!</span>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="relative bg-white/95 dark:bg-gray-900/95 backdrop-blur-sm shadow-lg rounded-lg border-2 border-gray-200 dark:border-gray-700"
    >
      <div className="absolute top-0 left-0 right-0 h-1.5 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500" />
      
      <div className="flex flex-col gap-3 pt-4">
        {error && (
          <div className="mx-2 text-sm text-red-600 dark:text-red-400 flex items-center justify-center gap-2 bg-red-50 dark:bg-red-900/30 py-2 rounded-lg shadow-sm">
            <AlertCircle className="w-4 h-4" />
            {error}
          </div>
        )}
        
        <div className="flex items-center justify-between px-4">
          <div className="flex items-center gap-3 flex-grow"> {/* flex-grow added here */}
            <div className="flex items-center gap-2">
              <MessageCircle className="w-5 h-5 text-blue-500" />
				<span className="text-large font-medium text-gray-700 dark:text-gray-300">
				  Was this {type === 'query' ? 'answer' : 'concept map'} helpful?
				</span>
            </div>
            <div className="flex gap-1 flex-shrink"> {/* flex-shrink added here */}
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  key={star}
                  onClick={() => {
                    setRating(star);
                    setShowComment(true);
                  }}
                  onMouseEnter={() => setHoverRating(star)}
                  onMouseLeave={() => setHoverRating(null)}
                  className={`p-2 text-xl rounded-full transition-all duration-200 hover:scale-125 ${
                    (hoverRating !== null ? star <= hoverRating : star <= rating)
                      ? 'text-yellow-400 hover:text-yellow-300' 
                      : 'text-gray-300 dark:text-gray-600 hover:text-yellow-400 dark:hover:text-yellow-400'
                  }`}
                >
                  ★
                </button>
              ))}
            </div>
          </div>


          {rating && !showComment && (
            <div className="flex items-center gap-2">
              <button
                onClick={() => setShowComment(true)}
                className="text-sm text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-300 font-medium transition-colors"
              >
                Add comment
              </button>
              <button
                onClick={handleSubmit}
                className="px-6 py-2 text-sm bg-gradient-to-r from-blue-600 to-blue-500
                  hover:from-blue-500 hover:to-blue-400
                  text-white rounded-lg transition-all duration-200
                  hover:shadow-lg hover:scale-105 font-medium"
              >
                Submit
              </button>
            </div>
          )}
        </div>

        {rating && showComment && (
          <div className="flex items-center gap-2 px-4 pb-4">
            <input
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Share your thoughts (optional)"
              className="flex-1 px-4 py-2 text-sm border rounded-lg 
                bg-white dark:bg-gray-800 
                border-gray-300 dark:border-gray-600
                text-gray-900 dark:text-gray-100
                placeholder-gray-500 dark:placeholder-gray-400
                focus:outline-none focus:ring-2 focus:ring-blue-500
                transition-all duration-200 shadow-sm"
            />
            <button
              onClick={handleSubmit}
              className="px-6 py-2 text-sm bg-gradient-to-r from-blue-600 to-blue-500
                hover:from-blue-500 hover:to-blue-400
                text-white rounded-lg transition-all duration-200
                hover:shadow-lg hover:scale-105 font-medium whitespace-nowrap shadow-sm"
            >
              <span className="hidden sm:inline">Submit Feedback</span>
              <span className="sm:hidden">Submit</span>
            </button>
          </div>
        )}
      </div>
    </motion.div>
  );
};







// SectionPlaceholder Component
const SectionPlaceholder = React.memo(
  ({
    title,
    icon: Icon,
    isLoading,
    content,
    isCollapsed,
    onToggleCollapse,
    renderCustomContent,
  }) => (
    <motion.div
      className={`relative rounded-lg overflow-hidden border-2 transition-all duration-300 ${
        isLoading ? 'border-blue-500' : 'border-gray-300 dark:border-gray-600'
      }`}
      initial={{ scale: 0.95, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.95, opacity: 0 }}
      whileHover={{
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
        borderColor: isLoading ? '#60a5fa' : '#3b82f6',
      }}
      transition={{ duration: 0.5 }}
    >
      <div
        className={`p-4 flex items-center gap-3 border-b border-gray-300 dark:border-gray-700 ${
          isLoading ? 'animate-pulse' : ''
        }`}
      >
        <Icon className="h-5 w-5 text-blue-500 dark:text-blue-400" aria-hidden="true" />
        <h2 className="font-bold text-gray-800 dark:text-gray-100 flex-grow">{title}</h2>
        {isLoading && (
          <Loader2
            className="h-4 w-4 text-gray-700 dark:text-gray-400 animate-spin mr-2"
            aria-label="Loading"
          />
        )}
        {!isLoading && (
          <button
            onClick={onToggleCollapse}
            className="text-gray-700 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
            aria-label={isCollapsed ? `Expand ${title}` : `Collapse ${title}`}
          >
            {isCollapsed ? (
              <ChevronDown className="h-5 w-5" aria-hidden="true" />
            ) : (
              <ChevronUp className="h-5 w-5" aria-hidden="true" />
            )}
          </button>
        )}
      </div>
      <AnimatePresence initial={false}>
        {!isCollapsed && (
          <motion.div
            key="content"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="overflow-hidden"
          >
            <div className="p-4">
              {isLoading ? (
                <p className="text-gray-700 dark:text-gray-400 italic">
                  Loading {title.toLowerCase()}...
                </p>
              ) : renderCustomContent ? (
                renderCustomContent()
              ) : (
                <div className="space-y-2">
                  {content?.map((line, lineIndex) => {
                    if (!line?.trim()) return null;
                    const trimmedLine = line.trim();
                    const isNumberedPoint = /^\d+\./.test(trimmedLine);
                    const isBulletPoint = /^-\s*/.test(trimmedLine);

                    return (
                      <div
                        key={lineIndex}
                        className={`
                          text-gray-800 dark:text-gray-200
                          ${isNumberedPoint ? 'flex gap-2 items-start' : ''}
                          ${isBulletPoint ? 'ml-4 flex gap-2 items-start' : ''}
                        `}
                      >
                        {isNumberedPoint && (
                          <span className="text-blue-500 dark:text-blue-400 font-medium">
                            {trimmedLine.match(/^\d+\./)[0]}
                          </span>
                        )}
                        {isBulletPoint && (
                          <span
                            className="text-blue-500 dark:text-blue-400 mt-1.5"
                            aria-hidden="true"
                          >
                            •
                          </span>
                        )}
                        <span>
                          {isNumberedPoint
                            ? trimmedLine.replace(/^\d+\.\s*/, '').trim()
                            : isBulletPoint
                            ? trimmedLine.replace(/^-\s*/, '').trim()
                            : trimmedLine}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
);

SectionPlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  isLoading: PropTypes.bool.isRequired,
  content: PropTypes.arrayOf(PropTypes.string),
  isCollapsed: PropTypes.bool.isRequired,
  onToggleCollapse: PropTypes.func.isRequired,
  renderCustomContent: PropTypes.func,
};

const RelationshipsSection = React.memo(({ diagram }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && diagram) {
      try {
        containerRef.current.innerHTML = '';
        const diagramId = `mermaid-diagram-${Date.now()}`;
        mermaid.render(diagramId, diagram)
          .then(({ svg }) => {
            if (containerRef.current) {
              containerRef.current.innerHTML = svg;
            }
          })
          .catch((error) => {
            if (containerRef.current) {
              containerRef.current.innerHTML = '<p class="text-red-500">Failed to render diagram</p>';
            }
          });
      } catch (error) {
        if (containerRef.current) {
          containerRef.current.innerHTML = '<p class="text-red-500">Failed to render diagram</p>';
        }
      }
    }
  }, [diagram]);

  return (
    <div className="overflow-x-auto">
      <div className="min-w-full">
        <div className="bg-gray-200 dark:bg-gray-800/50 rounded-lg border border-gray-300 dark:border-gray-700 p-4">
          <div className="text-sm text-gray-600 dark:text-gray-400 mb-4">
            This diagram shows the relationships between relevant legislation and regulations. 
            Solid lines indicate direct references, while dotted lines show reverse references.
          </div>
          <div 
            ref={containerRef}
            className="mermaid-diagram overflow-x-auto w-full"
          />
        </div>
      </div>
    </div>
  );
});

RelationshipsSection.propTypes = {
  diagram: PropTypes.string.isRequired,
};

const CitationTooltip = ({
  number,
  content,
  isOpen,
  onOpenChange,
  children
}) => {
  return (
    <Tooltip.Root open={isOpen} onOpenChange={onOpenChange} delayDuration={200}>
      <Tooltip.Trigger asChild>
        {children}
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content
          side="top"
          align="start"
          className="z-50 max-w-sm bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 p-4 animate-in fade-in-0 zoom-in-95"
          sideOffset={5}
        >
          <div className="space-y-2">
            <div className="flex items-start gap-2">
              <span className="inline-flex items-center justify-center h-5 min-w-[1.25rem] px-1.5 text-xs font-semibold rounded-full bg-blue-100 text-blue-600 dark:bg-blue-900/50 dark:text-blue-400">
                {number}
              </span>
              <div className="flex-1 text-sm text-gray-700 dark:text-gray-300">
                {content || (
                  <div className="flex items-center gap-2 text-yellow-600 dark:text-yellow-400">
                    <AlertCircle className="h-4 w-4" />
                    <span>Content not found for this citation</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Tooltip.Arrow className="fill-white dark:fill-gray-800" />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
};

const ValidationIndicator = ({ status }) => {
  const [showInfo, setShowInfo] = useState(false);

  if (!status) return null;

  const indicatorStyles = {
    checking: {
      container: "border-yellow-200 dark:border-yellow-800 bg-yellow-50 dark:bg-yellow-900/30",
      icon: "text-yellow-600 dark:text-yellow-400",
      text: "text-yellow-800 dark:text-yellow-300",
      hover: "hover:bg-yellow-100 dark:hover:bg-yellow-900/40"
    },
    valid: {
      container: "border-green-200 dark:border-green-800 bg-green-50 dark:bg-green-900/30",
      icon: "text-green-600 dark:text-green-400",
      text: "text-green-800 dark:text-green-300",
      hover: "hover:bg-green-100 dark:hover:bg-green-900/40"
    },
    invalid: {
      container: "border-red-200 dark:border-red-800 bg-red-50 dark:bg-red-900/30",
      icon: "text-red-600 dark:text-red-400",
      text: "text-red-800 dark:text-red-300",
      hover: "hover:bg-red-100 dark:hover:bg-red-900/40"
    }
  };

  const styles = indicatorStyles[status.type] || indicatorStyles.checking;
  
  const getIconComponent = (iconName) => {
    const iconMap = {
      'Scale': Scale,
      'Search': Search,
      'Shield': Shield,
      'PuzzlePiece': Puzzle,
      'Library': Library,
      'Skull': Skull,
      'ShieldCheck': ShieldCheck,
      'AlertTriangle': AlertTriangle
    };
    return iconMap[iconName] || AlertTriangle;
  };

  const StatusIcon = status.type === 'checking' 
    ? Scale 
    : status.persona?.emoji 
      ? getIconComponent(status.persona.emoji)
      : AlertTriangle;

  const ValidationTeamCard = ({ icon: Icon, name, description }) => (
    <div className="p-4 bg-gray-50 dark:bg-gray-800/50 rounded-lg border border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors">
      <div className="flex items-center gap-2 mb-2">
        <Icon className="h-5 w-5 text-blue-500 dark:text-blue-400" />
        <h4 className="font-medium text-gray-900 dark:text-gray-100">{name}</h4>
      </div>
      <p className="text-sm text-gray-600 dark:text-gray-300">{description}</p>
    </div>
  );

  return (
    <>
      <div className={`group relative flex items-center gap-3 p-3 border rounded-lg text-sm ${styles.container} transition-all duration-300`}>
        <StatusIcon className={`h-4 w-4 ${styles.icon}`} />
        
        <div className="flex-1">
          {status.type === 'checking' ? (
            <p className={`${styles.text} font-medium`}>
              {status.message}
            </p>
          ) : (
            <div>
              <p className={`${styles.text} font-medium`}>
                {status.persona?.name}: {status.persona?.catchphrase}
              </p>
              {status.details && (
                <p className={`mt-1 text-xs ${styles.text} opacity-90`}>
                  {status.details}
                </p>
              )}
            </div>
          )}
        </div>

        <Tooltip.Provider>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <button
                onClick={() => setShowInfo(true)}
                className={`p-1.5 rounded-full transition-colors ${styles.hover}`}
                aria-label="Learn more about validation"
              >
                <Info className={`h-4 w-4 ${styles.icon} opacity-70 group-hover:opacity-100 transition-opacity`} />
              </button>
            </Tooltip.Trigger>
            <Tooltip.Content
              side="top"
              align="end"
              className="bg-gray-800 text-white text-xs px-2 py-1 rounded shadow-lg"
            >
              Learn about our validation process
              <Tooltip.Arrow className="fill-gray-800" />
            </Tooltip.Content>
          </Tooltip.Root>
        </Tooltip.Provider>
      </div>

      <Dialog.Root open={showInfo} onOpenChange={setShowInfo}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" />
          <Dialog.Content 
            className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[600px] 
            transform -translate-x-1/2 -translate-y-1/2 rounded-lg 
            bg-white dark:bg-gray-900 
            border border-gray-200 dark:border-gray-800 
            shadow-xl dark:shadow-2xl shadow-black/10 dark:shadow-black/30
            p-6 z-50 overflow-y-auto 
            focus:outline-none"
          >
            <div className="space-y-6">
              <div className="flex justify-between items-center">
                <Dialog.Title className="text-xl font-bold text-gray-900 dark:text-gray-100 flex items-center gap-2">
                  <Scale className="h-6 w-6 text-blue-500 dark:text-blue-400" />
                  Response Validation System
                </Dialog.Title>
                <button
                  onClick={() => setShowInfo(false)}
                  className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 text-gray-500 dark:text-gray-400"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <div className="space-y-6">
                <section>
                  <h3 className="font-semibold text-lg text-gray-900 dark:text-gray-100 mb-3">
                    What We Check
                  </h3>
                  <div className="space-y-3 text-gray-700 dark:text-gray-300">
                    <p>Every response is automatically checked for:</p>
                    <ul className="space-y-3 ml-4">
                      <li className="flex items-start gap-2">
                        <span className="text-blue-500 dark:text-blue-400 mt-1.5">•</span>
                        <span>Query-Response Alignment: The answer must directly address what was asked</span>
                      </li>
                      <li className="flex items-start gap-2">
                        <span className="text-blue-500 dark:text-blue-400 mt-1.5">•</span>
                        <span>Legal Scope: Maintains research role without providing direct legal advice</span>
                      </li>
                      <li className="flex items-start gap-2">
                        <span className="text-blue-500 dark:text-blue-400 mt-1.5">•</span>
                        <span>Source Context: Citations and references must be used in their proper context</span>
                      </li>
                      <li className="flex items-start gap-2">
                        <span className="text-blue-500 dark:text-blue-400 mt-1.5">•</span>
                        <span>Coherence: Information must be logically consistent and properly contextualized</span>
                      </li>
                    </ul>
                  </div>
                </section>

                <section>
                  <h3 className="font-semibold text-lg text-gray-900 dark:text-gray-100 mb-3">
                    Our Validation Team
                  </h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <ValidationTeamCard
                      icon={Scale}
                      name="Judge Prudence"
                      description="Ensures responses maintain appropriate legal scope"
                    />
                    <ValidationTeamCard
                      icon={Search}
                      name="Detective Truth"
                      description="Verifies factual accuracy and proper context"
                    />
                    <ValidationTeamCard
                      icon={Library}
                      name="Ref Wreck"
                      description="Checks citation accuracy and relevance"
                    />
                    <ValidationTeamCard
                      icon={ShieldCheck}
                      name="The Mediocre Superhero"
                      description="Gives the final 'eh, looks good enough' stamp of approval"
                    />
                  </div>
                </section>

                <div className="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg border border-blue-200 dark:border-blue-800/60">
                  <h3 className="font-semibold text-blue-800 dark:text-blue-300 mb-2">
                    Important Note
                  </h3>
                  <p className="text-blue-700 dark:text-blue-200 text-sm leading-relaxed">
                    While our validation system helps maintain quality and accuracy, it's not infallible. 
                    Always verify information through official sources and consult qualified legal professionals 
                    for specific advice.
                  </p>
                </div>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

ValidationIndicator.propTypes = {
  status: PropTypes.shape({
    type: PropTypes.oneOf(['checking', 'valid', 'invalid']).isRequired,
    message: PropTypes.string,
    details: PropTypes.string,
    severity: PropTypes.string,
    category: PropTypes.string,
    persona: PropTypes.shape({
      name: PropTypes.string,
      emoji: PropTypes.string,
      catchphrase: PropTypes.string
    })
  })
};



const EnhancedCitation = ({ number }) => {
  return (
    <sup
      className="
        inline-flex items-center justify-center
        h-4
        min-w-[1em] px-[0.25em]
        text-[0.7em] font-semibold
        rounded-full
        bg-blue-100 text-blue-600
        dark:bg-blue-900/50 dark:text-blue-400
        ml-0.5 select-none
        leading-none
      "
      style={{
        // Ensures vertical alignment and consistent shape
        lineHeight: 'normal'
      }}
    >
      {number}
    </sup>
  );
};

const DynamicProgressMessage = ({ message }) => {
  // Function to parse and render message with icons
  const renderMessage = (text) => {
    if (!text) return null;

    // Regular expression to match <IconName> tags
    const parts = text.split(/(<[A-Z][a-zA-Z]+?>)/);
    
    return parts.map((part, index) => {
      // Check if this part matches our icon pattern
      const iconMatch = part.match(/<([A-Z][a-zA-Z]+?)>/);
      
      if (iconMatch) {
        const iconName = iconMatch[1];
        const Icon = LucideIcons[iconName];
        
        if (Icon) {
          return (
            <span key={index} className="inline-flex items-center">
              <Icon className="h-5 w-5 text-blue-500 mx-1" aria-hidden="true" />
            </span>
          );
        }
      }
      
      // Return regular text for non-icon parts
      // Also handle emojis gracefully if they're still present
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <div className="flex items-center gap-2 text-gray-900 dark:text-gray-100">
      {renderMessage(message)}
    </div>
  );
};


const ConceptMap = ({ d2Code }) => {
  const diagramRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const renderTimeoutRef = useRef(null);
  
  // Add summary popup state
  const [summaryPopup, setSummaryPopup] = useState({ 
    isOpen: false, 
    content: '', 
    position: { x: 0, y: 0 } 
  });
  
  // Add event handlers after diagram is rendered
useEffect(() => {
  if (!loading && diagramRef.current) {
    // Handle clicks on the SVG elements
    const handleNodeClick = (e) => {
      e.preventDefault();
      const node = e.currentTarget;
      // Try both href and xlink:href
      const href = node.getAttribute('href') || node.getAttributeNS('http://www.w3.org/1999/xlink', 'href');
      
      if (href) {
        const match = href.match(/javascript:showSummary\(`(.*)`\)/);
        if (match) {
          const summary = match[1];
          setSummaryPopup({
            isOpen: true,
            content: summary,
            position: {
              x: e.clientX,
              y: e.clientY
            }
          });
        }
      }
    };

    // Add click handlers to all 'a' elements in the SVG
    const links = diagramRef.current.querySelectorAll('a');
    links.forEach(link => {
      link.style.cursor = 'pointer';
      link.addEventListener('click', handleNodeClick);
    });

    // Cleanup
    return () => {
      const links = diagramRef.current?.querySelectorAll('a');
      links?.forEach(link => {
        link.removeEventListener('click', handleNodeClick);
      });
    };
  }
}, [loading]);
  
    // Add styled popup dialog
  const Popup = () => (
    <Dialog.Root 
      open={summaryPopup.isOpen} 
      onOpenChange={(open) => setSummaryPopup(prev => ({ ...prev, isOpen: open }))}
    >
      <Dialog.Portal>
        <Dialog.Overlay 
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" 
          onClick={() => setSummaryPopup(prev => ({ ...prev, isOpen: false }))}
        />
<Dialog.Content
  className="fixed z-50 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-xl 
    border border-gray-200 dark:border-gray-700 
    w-[90vw] max-w-2xl max-h-[85vh] overflow-y-auto
    top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
    mx-4 transition-all"
>
  {/* Title bar */}
  <div className="flex justify-between items-center mb-4 pb-3 border-b border-gray-200 dark:border-gray-700">
    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
      Legislation Details
    </h3>
    <button
      onClick={() => setSummaryPopup(prev => ({ ...prev, isOpen: false }))}
      className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full 
        text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 
        transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
      aria-label="Close"
    >
      <X className="h-5 w-5" />
    </button>
  </div>

  {/* Content */}
  <div className="text-base leading-relaxed text-gray-700 dark:text-gray-300 
    prose dark:prose-invert max-w-none overflow-y-auto"
  >
    {summaryPopup.content}
  </div>

  {/* Optional footer */}
  <div className="mt-6 pt-3 border-t border-gray-200 dark:border-gray-700 flex justify-end">
    <button
      onClick={() => setSummaryPopup(prev => ({ ...prev, isOpen: false }))}
      className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg 
        transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      Close
    </button>
  </div>
</Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );

  // Add showSummary handler
const showSummary = useCallback((content) => {
  // Get the current mouse position from the window event
  const event = window.event;
  setSummaryPopup({
    isOpen: true,
    content,
    position: {
      x: event.clientX,
      y: event.clientY
    }
  });
}, []);

  // Add effect to set global handler
  useEffect(() => {
    window.showSummary = showSummary;
    return () => {
      delete window.showSummary;
    };
  }, [showSummary]);

  const downloadSVG = useCallback(() => {
    const svgElement = diagramRef.current?.querySelector('svg');
    if (!svgElement) return;

    // Create a copy of the SVG to modify
    const svgClone = svgElement.cloneNode(true);
    
    // Ensure proper styling in the downloaded version
    const styles = document.createElementNS("http://www.w3.org/2000/svg", "style");
    styles.textContent = `
      * { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif; }
      .edge { stroke-width: 2px; }
      text { font-size: 14px; }
    `;
    svgClone.insertBefore(styles, svgClone.firstChild);

    // Convert to string
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svgClone);
    
    // Create download link
    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'concept-map.svg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, []);

  const downloadPNG = useCallback(() => {
    const svgElement = diagramRef.current?.querySelector('svg');
    if (!svgElement) return;

    // Create a canvas with higher resolution
    const canvas = document.createElement('canvas');
    const scale = 2; // Increase for higher resolution
    canvas.width = svgElement.clientWidth * scale;
    canvas.height = svgElement.clientHeight * scale;
    const ctx = canvas.getContext('2d');
    ctx.scale(scale, scale);

    // Convert SVG to data URL
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svgElement);
    const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(svgBlob);

    // Create image and draw to canvas
    const img = new Image();
    img.onload = () => {
      ctx.fillStyle = 'white'; // Set background color
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, svgElement.clientWidth, svgElement.clientHeight);
      
      // Download the PNG
      const pngUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = pngUrl;
      link.download = 'concept-map.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    };
    img.src = url;
  }, []);

  const renderDiagram = useCallback(async () => {
    if (!diagramRef.current || !d2Code) return;

    try {
      setLoading(true);
      setError(null);
      
      const d2Instance = new D2();
      await d2Instance.ready;

      const isDark = document.documentElement.classList.contains('dark');
      
      // Extract the actual d2 code from the response structure
      let actualD2Code;
      if (typeof d2Code === 'object' && d2Code.content && d2Code.content.d2_code) {
        actualD2Code = d2Code.content.d2_code;
      } else if (typeof d2Code === 'string') {
        actualD2Code = d2Code;
      } else {
        throw new Error('Invalid d2 code format');
      }

      // Add background for dark mode
      if (isDark) {
        actualD2Code = `# Set dark theme
direction: down
style.fill: "#111827"

${actualD2Code}`;
      }

      // Compile the D2 code
      const compiledResult = await d2Instance.compile(actualD2Code);

      // Check for compilation errors
      if (compiledResult.errors && compiledResult.errors.length > 0) {
        throw new Error(
          `Compilation errors: ${compiledResult.errors.map(e => e.message).join(', ')}`
        );
      }

      const containerId = `d2-container-${Math.random().toString(36).substr(2, 9)}`;
      diagramRef.current.id = containerId;

      // Get the container dimensions
      const containerWidth = diagramRef.current.clientWidth;
      const containerHeight = diagramRef.current.clientHeight || 600; // Provide default height

      // Configure layout with responsive dimensions
      const layout = {
        container: `#${containerId}`,
        theme: 'light', // Keep light theme for consistent colors
        layout: 'dagre',
        pad: 20,
        width: Math.max(containerWidth - 40, 300),
        height: Math.max(containerHeight - 40, 400),
      };

      // Render and get the SVG
      const svg = await d2Instance.render(compiledResult.diagram, layout);
      
      // Insert the SVG into the container
      if (diagramRef.current) {
        diagramRef.current.innerHTML = svg;
      }
	  
// Inside renderDiagram, after diagramRef.current.innerHTML = svg;
if (diagramRef.current) {
  // Add click handlers to all nodes
  const nodes = diagramRef.current.querySelectorAll('.node');
  nodes.forEach(node => {
    node.style.cursor = 'pointer';
    node.addEventListener('click', (e) => {
      // Extract node ID from href attribute
      const link = node.querySelector('a');
      if (link) {
        e.preventDefault();
        const href = link.getAttribute('href');
        if (href) {
          // Extract the summary text from the javascript:showSummary call
          const match = href.match(/javascript:showSummary\('([^']+)'\)/);
          if (match) {
            const summary = match[1];
            setSummaryPopup({
              isOpen: true,
              content: summary,
              position: {
                x: e.clientX,
                y: e.clientY
              }
            });
          }
        }
      }
    });
  });
}	  

      setLoading(false);
    } catch (error) {
      setError(error.message || 'Failed to render diagram');
      setLoading(false);
    }
  }, [d2Code]);

  // Initial render
  useEffect(() => {
    renderDiagram();
  }, [renderDiagram]);

  // Handle theme changes
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          if (renderTimeoutRef.current) {
            clearTimeout(renderTimeoutRef.current);
          }
          renderTimeoutRef.current = setTimeout(renderDiagram, 100);
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class']
    });

    return () => {
      observer.disconnect();
      if (renderTimeoutRef.current) {
        clearTimeout(renderTimeoutRef.current);
      }
    };
  }, [renderDiagram]);

  // Handle resize events
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (renderTimeoutRef.current) {
        clearTimeout(renderTimeoutRef.current);
      }
      renderTimeoutRef.current = setTimeout(renderDiagram, 100);
    });
    
    if (diagramRef.current) {
      resizeObserver.observe(diagramRef.current);
    }
    
    return () => {
      resizeObserver.disconnect();
      if (renderTimeoutRef.current) {
        clearTimeout(renderTimeoutRef.current);
      }
    };
  }, [renderDiagram]);

  return (
    <div className="relative w-full mb-16">
      <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-700 p-4 transition-colors duration-200">
        {/* Download buttons */}
        <div className="absolute top-2 right-2 flex gap-1 z-20">
          <button
            onClick={downloadSVG}
            disabled={loading || error}
            className="px-2 py-1 bg-blue-600 hover:bg-blue-700 disabled:bg-blue-400 
              text-white rounded-md text-xs font-medium flex items-center gap-1 
              transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <Download className="h-3 w-3" />
            SVG
          </button>
          <button
            onClick={downloadPNG}
            disabled={loading || error}
            className="px-2 py-1 bg-green-600 hover:bg-green-700 disabled:bg-green-400 
              text-white rounded-md text-xs font-medium flex items-center gap-1 
              transition-colors focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            <Download className="h-3 w-3" />
            PNG
          </button>
        </div>

        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white/50 dark:bg-gray-900/50 backdrop-blur-sm z-10">
            <div className="flex flex-col items-center gap-2">
              <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
              <span className="text-sm text-gray-600 dark:text-gray-400">
                Rendering diagram...
              </span>
            </div>
          </div>
        )}

        {error && (
          <div className="absolute inset-0 flex items-center justify-center z-10">
            <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-lg p-4 max-w-md">
              <div className="flex items-center gap-2 text-red-600 dark:text-red-400">
                <AlertCircle className="h-5 w-5 flex-shrink-0" />
                <div className="text-sm font-medium">Error rendering diagram</div>
              </div>
              <p className="mt-2 text-sm text-red-600 dark:text-red-400">
                {error}
              </p>
            </div>
          </div>
        )}

        <div 
          ref={diagramRef} 
          className="w-full relative z-0"
          style={{ 
            overflow: 'hidden',
            minHeight: '600px'
          }}
        />

        {/* Add Summary Popup */}
        <Dialog.Root open={summaryPopup.isOpen} onOpenChange={() => setSummaryPopup(prev => ({ ...prev, isOpen: false }))}>
          <Dialog.Portal>
            <Dialog.Overlay 
              className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" 
              onClick={() => setSummaryPopup(prev => ({ ...prev, isOpen: false }))}
            />
<Dialog.Content
  className="fixed z-50 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-xl 
    border border-gray-200 dark:border-gray-700 
    w-[90vw] max-w-2xl max-h-[85vh] overflow-y-auto
    top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
    mx-4 transition-all"
>
  {/* Title bar */}
  <div className="flex justify-between items-center mb-4 pb-3 border-b border-gray-200 dark:border-gray-700">
    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
      Legislation Details
    </h3>
    <button
      onClick={() => setSummaryPopup(prev => ({ ...prev, isOpen: false }))}
      className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full 
        text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 
        transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
      aria-label="Close"
    >
      <X className="h-5 w-5" />
    </button>
  </div>

  {/* Content */}
  <div className="text-base leading-relaxed text-gray-700 dark:text-gray-300 
    prose dark:prose-invert max-w-none overflow-y-auto"
  >
    {summaryPopup.content}
  </div>
</Dialog.Content>

          </Dialog.Portal>
        </Dialog.Root>
      </div>

    </div>
  );
};







const FormattedAnswer = React.memo(
  ({
    answer = '',
    loadingSections,
    result,
    sectionsConfig,
    collapsedSections,
    onToggleCollapse,
    wsRef,
    selectedScreenshot,
    setSelectedScreenshot,
    thoughtLogs = []
  }) => {
    const [validationStatus, setValidationStatus] = useState(null);
    const [showThoughts, setShowThoughts] = useState(false);
    
    // Updated Regex for Section Headers
    const sectionHeaderRegex = /(?:^|\n)(?:\d+\.?\s*)(DIRECT ANSWER|LEGAL BASIS|KEY REQUIREMENTS|LIMITATIONS AND EXCEPTIONS):?/gi;
    
    const normalizedAnswer = (answer || '')
      .replace(/\r\n/g, '\n')
      .replace(/\r/g, '\n')
      .trim();

	// Extract Sections Using Updated Regex
const extractSections = useMemo(() => {
  if (!normalizedAnswer) return [];

  const sections = [];
  let currentSection = null;
  let currentContent = [];

  // Split by lines and process each line
  const lines = normalizedAnswer.split('\n').map(line => line.trim());
  
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    
    // Skip empty lines
    if (!line) continue;
    
    // Check for section headers - now handles both with and without line breaks
    const headerMatch = line.match(/^(\d+)\.\s*(DIRECT ANSWER|LEGAL BASIS|KEY REQUIREMENTS|LIMITATIONS AND EXCEPTIONS):\s*(.*)$/i);
    
    if (headerMatch) {
      // If we were building a previous section, save it
      if (currentSection && currentContent.length > 0) {
        sections.push({
          title: currentSection,
          content: currentContent
        });
      }
      
      // Start new section
      currentSection = headerMatch[2].toUpperCase();
      currentContent = [];
      
      // If there's content after the header on the same line, add it
      if (headerMatch[3]) {
        currentContent.push(headerMatch[3]);
      }
    } else if (currentSection) {
      // Handle regular content lines as before
      if (currentSection === 'DIRECT ANSWER') {
        currentContent.push(line);
      } else {
        const citationMatch = line.match(/^[¹²³⁴⁵⁶⁷⁸⁹⁰]+|^\[\d+\]/);
        if (citationMatch) {
          currentContent.push(line);
        } else if (currentContent.length > 0) {
          if (line.includes('Page') || line.includes('Schedule')) {
            currentContent.push(line);
          } else {
            currentContent[currentContent.length - 1] += ' ' + line;
          }
        }
      }
    }
  }

  // Add final section if exists
  if (currentSection && currentContent.length > 0) {
    sections.push({
      title: currentSection,
      content: currentContent
    });
  }

  return sections;
}, [normalizedAnswer]);






    // Handle WebSocket Messages for Validation
    useEffect(() => {
      if (wsRef.current) {
        const handleMessage = (event) => {
          try {
            const data = JSON.parse(event.data);

            // Handle validation start message
            if (data.type === 'validation_start') {
              setValidationStatus({
                type: 'checking',
                message: 'Checking response for potential issues...'
              });
            }
            // Handle bias check results
            else if (data.type === 'bias_check') {
              setValidationStatus({
                type: data.has_issue ? 'invalid' : 'valid',
                message: data.has_issue ? 'Potential issues detected' : 'No major issues detected',
                details: data.has_issue ? data.reason : null,
                severity: data.severity,
                category: data.category,
                persona: data.persona
              });
            }
            // Handle other message types as needed
          } catch (err) {
          }
        };

        wsRef.current.addEventListener('message', handleMessage);
        return () => {
          if (wsRef.current) {
            wsRef.current.removeEventListener('message', handleMessage);
          }
        };
      }
    }, [wsRef]);

    // Process Content Lines
	const processContent = (content) => {
	  const superscriptMap = {
		'⁰': '0',	
		'¹': '1',
		'²': '2',
		'³': '3',
		'⁴': '4',
		'⁵': '5',
		'⁶': '6',
		'⁷': '7',
		'⁸': '8',
		'⁹': '9'
	  };

	  const citationRegex = /\[(\d+)\]|([⁰¹²³⁴⁵⁶⁷⁸⁹]+)/g;

	  const getTitleFromLine = (line) => {
		const match = line.match(/([A-Za-z\s]+(?:Act|Regulations|Rules)(?:\s+\d+)?),\s*Section/);
		return match ? match[1].trim() : 'Document';
	  };

	  return content.map((line, lineIndex) => {
		if (!line || typeof line !== 'string') return null;

		const parts = [];
		let lastIndex = 0;

		const quoteMatch = line.match(/"([^"]+)"/);
		const quote = quoteMatch ? quoteMatch[1] : '';
		const title = getTitleFromLine(line);

		// Process citations and ANZSOC codes
		let citationMatch;
		while ((citationMatch = citationRegex.exec(line)) !== null) {
		  parts.push(line.slice(lastIndex, citationMatch.index));
		  lastIndex = citationRegex.lastIndex;

		  let number;
		  if (citationMatch[1]) {
			number = citationMatch[1];
		  } else if (citationMatch[2]) {
			// Get the actual superscript character
			const superscript = citationMatch[2];
			// Convert to number using our map
			number = superscriptMap[superscript];
		  }

		  // Add citation
		  parts.push(
			<EnhancedCitation
			  key={`citation-${lineIndex}-${number}`}
			  number={number}
			/>
		  );

		if (result?.anzsoc_codes) {
		  // Get the actual superscript character we found in the text
		  const superscript = citationMatch[2];
		  
		  const matchingCode = result.anzsoc_codes.find(code => {
			return code.citations && code.citations.includes(superscript);
		  });
		  
		  if (matchingCode) {
			parts.push(
			  <ANZSOCBadge 
				key={`anzsoc-${lineIndex}-${superscript}`} 
				code={matchingCode} 
			  />
			);
		  }
		}
		}
		// Handle remaining text and screenshots
		let remainingText = line.slice(lastIndex);
		const pageWithScreenshotRegex = /Page\s+(\d+(?:\.\d+)?)\s*\[([^\]]+\.(?:jpg|jpeg|png|gif))\]/gi;
		let screenshotMatch;

		while ((screenshotMatch = pageWithScreenshotRegex.exec(remainingText)) !== null) {
		  const [fullMatch, rawPageNum, filename] = screenshotMatch;
		  const matchStart = screenshotMatch.index;

		  parts.push(remainingText.slice(0, matchStart));
		  parts.push(
			<button
			  key={`${lineIndex}-${rawPageNum}-${filename}`}
			  onClick={() => {
				setSelectedScreenshot({
				  imageUrl: filename,
				  title: title,
				  pageNumber: Math.floor(parseFloat(rawPageNum)),
				  quote: quote,
				});
			  }}
			  className="inline-flex items-center gap-1.5 px-2 py-1 bg-blue-50 hover:bg-blue-100 
				dark:bg-blue-900/30 dark:hover:bg-blue-900/50 text-blue-600 dark:text-blue-400 
				rounded-md border border-blue-200 dark:border-blue-800 text-sm font-medium 
				transition-colors duration-200 group focus:outline-none focus:ring-2 focus:ring-blue-500"
			>
			  <span className="flex items-center gap-1">
				<BookOpen className="w-3.5 h-3.5" />
				Page {Math.floor(parseFloat(rawPageNum))}
			  </span>
			</button>
		  );

		  remainingText = remainingText.slice(matchStart + fullMatch.length);
		}

		parts.push(remainingText);

		return <div key={lineIndex}>{parts}</div>;
	  });
	};


    const allSections = [
      'DIRECT ANSWER',
      'LEGAL BASIS',
      'KEY REQUIREMENTS',
      'LIMITATIONS AND EXCEPTIONS',
      'SOURCES',
    ];

    return (
      <div className="space-y-6">
        {/* Thought Logs Button and Modal */}
        {thoughtLogs.length > 0 && (
          <div className="fixed bottom-24 right-6 z-40">
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <button
                    onClick={() => setShowThoughts(true)}
                    className="p-3 rounded-full bg-blue-600 text-white shadow-lg hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center gap-2"
                    aria-label="View Analysis Process"
                    aria-expanded={showThoughts}
                  >
                    <Brain className="h-5 w-5" />
                  </button>
                </Tooltip.Trigger>
                <Tooltip.Content
                  side="left"
                  className="bg-gray-800 text-white text-sm px-2 py-1 rounded shadow-lg"
                >
                  View Analysis Process
                  <Tooltip.Arrow className="fill-gray-800" />
                </Tooltip.Content>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
        )}

        <ThoughtModal 
          isOpen={showThoughts}
          onClose={() => setShowThoughts(false)}
          thoughts={thoughtLogs}
        />

        {/* Image Modal */}
        {selectedScreenshot && (
          <ImageModal
            isOpen={!!selectedScreenshot}
            onClose={() => setSelectedScreenshot(null)}
            imageUrl={selectedScreenshot.imageUrl}
            title={selectedScreenshot.title}
            pageNumber={selectedScreenshot.pageNumber}
            quote={selectedScreenshot.quote}
            highlight={selectedScreenshot.highlight}
          />
        )}

        {/* Validation Status */}
        {validationStatus && (
          <ValidationIndicator status={validationStatus} />
        )}

        {/* Extracted Sections */}
        {allSections.map((sectionTitle) => {
          const section = extractSections.find((s) => s.title === sectionTitle);
          const isLoading = loadingSections.includes(sectionTitle) && !section;
          const isCollapsed = collapsedSections[sectionTitle];

          if (sectionTitle === 'SOURCES' && result?.sources) {
            return (
              <div key={sectionTitle} className="space-y-6">
                <SectionPlaceholder
                  title={sectionTitle}
                  icon={sectionsConfig[sectionTitle]?.icon}
                  isLoading={isLoading}
                  isCollapsed={isCollapsed}
                  onToggleCollapse={() => onToggleCollapse(sectionTitle)}
                  renderCustomContent={() => (
                    <div className="space-y-3">
                      {result.sources.map((source, index) => (
                        <div
                          key={index}
                          className="bg-gray-200 dark:bg-gray-800/50 rounded-lg border border-gray-300 dark:border-gray-700 p-3"
                        >
                          <div className="flex items-start justify-between gap-4">
                            <div className="flex-grow">
                              <h4 className="font-medium text-gray-800 dark:text-gray-200">
                                {source.title}
                              </h4>
                              {source.pages?.length > 0 && (
                                <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                  Pages cited: {source.pages.join(', ')}
                                </p>
                              )}
                            </div>
                            {source.url ? (
                              <a 
                                href={source.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center gap-2 px-3 py-2 text-sm bg-blue-600/20 text-blue-600 dark:text-blue-400 rounded-md hover:bg-blue-600/30 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                                aria-label={`View ${source.title} online`}
                              >
                                <ExternalLink className="h-4 w-4" aria-hidden="true" />
                                View Online
                              </a>
                            ) : (
                              <span className="text-xs text-red-500">URL not available</span>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />

                {result?.mermaid_diagram && (
                  <SectionPlaceholder
                    title="Legislative Relationships"
                    icon={Share2}
                    isLoading={false}
                    isCollapsed={collapsedSections['LEGISLATIVE RELATIONSHIPS']}
                    onToggleCollapse={() => onToggleCollapse('LEGISLATIVE RELATIONSHIPS')}
                    renderCustomContent={() => (
                      <MermaidDiagram diagram={result.mermaid_diagram} />
                    )}
                  />
                )}
              </div>
            );
          }

          return (
            <SectionPlaceholder
              key={sectionTitle}
              title={sectionTitle}
              icon={sectionsConfig[sectionTitle]?.icon}
              isLoading={isLoading}
              isCollapsed={isCollapsed}
              onToggleCollapse={() => onToggleCollapse(sectionTitle)}
              renderCustomContent={
                section
                  ? () => (
                      <div className="space-y-2">
                        {processContent(section.content)}
                      </div>
                    )
                  : () => (
                      <div className="text-gray-700 dark:text-gray-300 italic">
                        Section not available.
                      </div>
                    )
              }
            />
          );
        })}
      </div>
    );
  }
);

FormattedAnswer.propTypes = {
  answer: PropTypes.string,
  loadingSections: PropTypes.arrayOf(PropTypes.string).isRequired,
  result: PropTypes.object,
  sectionsConfig: PropTypes.object.isRequired,
  collapsedSections: PropTypes.object.isRequired,
  onToggleCollapse: PropTypes.func.isRequired,
  wsRef: PropTypes.object.isRequired,  
  selectedScreenshot: PropTypes.object,
  setSelectedScreenshot: PropTypes.func.isRequired,
  thoughtLogs: PropTypes.array
};

const ConnectionStatus = ({ status }) => {
  const statusConfig = {
    connected: {
      color: 'bg-green-500',
      text: 'Connected'
    },
    connecting: {
      color: 'bg-yellow-500',
      text: 'Connecting...'
    },
    disconnected: {
      color: 'bg-red-500',
      text: 'Disconnected'
    }
  };

  const config = statusConfig[status];

  return (
    <div className="fixed bottom-4 right-4 flex items-center gap-2 px-3 py-1.5 rounded-full shadow-lg bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 transition-all duration-300">
      <div className={`w-2 h-2 rounded-full ${config.color}`} />
      <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
        {config.text}
      </span>
    </div>
  );
};

// Main App Component
export default function App() {
  // Theme and Font Size State
  const [theme, setTheme] = useState(() => localStorage.getItem('theme') || 'light');
  const [fontSize, setFontSize] = useState(() => localStorage.getItem('fontSize') || 'normal');
  
  const [summaryPopup, setSummaryPopup] = useState({ 
	  isOpen: false, 
	  content: '', 
	  position: { x: 0, y: 0 } 
  });
  
useEffect(() => {
  // Check if user is a bot first
  const userAgent = navigator.userAgent.toLowerCase();
  const isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(userAgent);
  
  if (isBot) {
    setShowDisclaimer(false);
    return;
  }

  // Always show disclaimer for non-bot users
  setShowDisclaimer(true);
}, []); // Empty dependency array means this runs once on mount


  // Other States
  const [thoughtLogs, setThoughtLogs] = useState([]); 
  const [connectionStatus, setConnectionStatus] = useState('connecting');
  const [prevChunk, setPrevChunk] = useState('');
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showSafety, setShowSafety] = useState(false); 
  const [question, setQuestion] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const [error, setError] = useState(null);
  const [history, setHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [actionMessage, setActionMessage] = useState('');
  const [connected, setConnected] = useState(() => {
    const isBot = /bot|googlebot|crawler|spider|robot|crawling|inspection|Google-InspectionTool/i.test(navigator.userAgent);
    return isBot ? true : false;
  });
  const [currentSection, setCurrentSection] = useState(null);
  const [loadingSections, setLoadingSections] = useState([]);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [collapsedSections, setCollapsedSections] = useState({
    'DIRECT ANSWER': false,
    'LEGAL BASIS': false,
    'KEY REQUIREMENTS': false,
    'LIMITATIONS AND EXCEPTIONS': false,
    'SOURCES': false,
    'RELATIONSHIPS': false,
  });
  const [selectedJurisdiction, setSelectedJurisdiction] = useState('SA');
  const [selectedScreenshot, setSelectedScreenshot] = useState(null);
  
  const [searchMode, setSearchMode] = useState('search'); // 'search' or 'concept'

  const wsRef = useRef(null);
  const manualCloseRef = useRef(false);
  
  const [searchResults, setSearchResults] = useState(null);
  const [conceptResults, setConceptResults] = useState(null);

  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  
  const [isReconnecting, setIsReconnecting] = useState(false);
  const MAX_RECONNECT_ATTEMPTS = 5;
  const WS_KEEPALIVE_INTERVAL = 30000;
  const WS_RESPONSE_TIMEOUT = 45000;  

useEffect(() => {
  const handleOnline = () => {
    if (!connected && !isReconnecting) {
      connectWebSocket();
    }
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && !connected && !isReconnecting) {
      connectWebSocket();
    }
  };

  window.addEventListener('online', handleOnline);
  document.addEventListener('visibilitychange', handleVisibilityChange);

  return () => {
    window.removeEventListener('online', handleOnline);
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}, [connected, isReconnecting]);  

  // Sections Configuration
  const sectionsConfig = useMemo(
    () => ({
      'DIRECT ANSWER': { icon: Lightbulb },
      'LEGAL BASIS': { icon: BookOpen },
      'KEY REQUIREMENTS': { icon: CheckCircle },
      'LIMITATIONS AND EXCEPTIONS': { icon: AlertTriangle },
      SOURCES: { icon: Link },
    }),
    []
  );
  
	const [compareMode, setCompareMode] = useState(false);
	const [selectedJurisdictions, setSelectedJurisdictions] = useState(['SA']); // Now an array

  
	const JURISDICTIONS = [
	  {
		name: 'South Australia',
		code: 'SA',
		available: true,
		acts: 569,        // Updated from 570 to 569
		regulations: 469, // Updated from 468 to 469
	  },
	  {
		name: 'Victoria', 
		code: 'VIC',
		available: true,  
		acts: 777,        // Updated to 777
		regulations: 408, // Updated to 408
	  },
	  {
		name: 'Commonwealth (Federal)',
		code: 'CWTH',
		available: true,
		acts: 4636,        
		regulations: 0,    
	  }	  
	];


	const isBot = useMemo(() => {
	  return /bot|googlebot|crawler|spider|robot|crawling|inspection|Google-InspectionTool/i.test(
		navigator.userAgent
	  );
	}, []);

  useEffect(() => {
    const isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(
      navigator.userAgent.toLowerCase()
    );
    
    if (isBot) {
      // Remove scroll locks and pointer-events restrictions
      document.body.style.removeProperty('pointer-events');
      document.body.removeAttribute('data-scroll-locked');
      document.documentElement.setAttribute('data-bot', 'true');
      setShowDisclaimer(false); // Don't show disclaimer for bots
    }
  }, []);

const selectedJurisdictionName = useMemo(() => {
  const primaryCode = selectedJurisdictions[0];
  return JURISDICTIONS.find((j) => j.code === primaryCode)?.name || '';
}, [selectedJurisdictions]);

const selectedJurisdictionAvailable = useMemo(() => {
  const primaryCode = selectedJurisdictions[0];
  return JURISDICTIONS.find((j) => j.code === primaryCode)?.available;
}, [selectedJurisdictions]);

  // Initialize Theme and Font Size
	useEffect(() => {
	  const savedTheme = localStorage.getItem('theme') || 'light';
	  const savedFontSize = localStorage.getItem('fontSize') || 'normal';
	  setTheme(savedTheme);
	  setFontSize(savedFontSize);
	  
	  // Set the CSS variable for the font size
	  document.documentElement.style.setProperty(
		'--base-font-size',
		savedFontSize === 'large' ? '1.125rem' : '1rem'
	  );
	}, []);
	
useEffect(() => {
  if (!compareMode && selectedJurisdictions.length > 1) {
    // If leaving compare mode with multiple selections, keep only the first one
    setSelectedJurisdictions([selectedJurisdictions[0]]);
  }
}, [compareMode]);	

  // Apply Theme
  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.toggle('dark', theme === 'dark');
    localStorage.setItem('theme', theme);
  }, [theme]);

  // Save Font Size
  useEffect(() => {
    localStorage.setItem('fontSize', fontSize);
  }, [fontSize]);

  // WebSocket Connection
  useEffect(() => {
    connectWebSocket();
    return () => {
      manualCloseRef.current = true;
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.close();
      }
    };
  }, []);
  

const connectWebSocket = () => {
  // Guard against multiple connection attempts
  if (isReconnecting || wsRef.current?.readyState === WebSocket.CONNECTING) {
    return;
  }
  
  setIsReconnecting(true);
  setConnectionStatus('connecting');

  const WS_URL = 'wss://2lv389ryo5.execute-api.ap-southeast-2.amazonaws.com/production';
  const CONNECTION_TIMEOUT = 5000;
  const RETRY_DELAY = 3000;
  
  let keepaliveInterval;
  let responseTimeout;
  let connectionTimeout;
  let isCleanupCalled = false;

  const cleanup = () => {
    if (isCleanupCalled) return;
    isCleanupCalled = true;
    
    clearTimeout(connectionTimeout);
    clearInterval(keepaliveInterval);
    clearTimeout(responseTimeout);
    
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      wsRef.current.close();
    }
  };

  const websocket = new WebSocket(WS_URL);
  
  // Setup connection timeout
  connectionTimeout = setTimeout(() => {
    if (websocket.readyState !== WebSocket.OPEN) {
      cleanup();
      setConnected(false);
      setIsReconnecting(false);
      
      // Exponential backoff for retry
      const retryDelay = Math.min(RETRY_DELAY * Math.pow(2, reconnectAttempts), 30000);
      if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
        setTimeout(() => {
          if (!connected) {
            setReconnectAttempts(prev => prev + 1);
            connectWebSocket();
          }
        }, retryDelay);
      }
    }
  }, CONNECTION_TIMEOUT);
  
  

  const handleMessage = (event) => {
    try {
      // Reset timeout on any message
      if (responseTimeout) clearTimeout(responseTimeout);
      
      responseTimeout = setTimeout(() => {
        setLoading(false);
        setProgressMessage('');
        cleanup();
      }, WS_RESPONSE_TIMEOUT);

      if (!event.data) return;
      const data = JSON.parse(event.data);

      // Handle keepalive
      if (data.type === 'keepalive') {
        websocket.send(JSON.stringify({ type: 'keepalive_ack' }));
        return;
      }
	  
    // Handle concept map response
    if (data.type === 'concept_map') {
      setResult({
        type: 'concept_map',
        d2Code: data.content.d2_code,
        concept: data.content.concept
      });
      setLoading(false);
      return;
    }	  
	  
	if (data.type === 'anzsoc_codes') {
	  setResult(prev => ({
		...prev,
		anzsoc_codes: data.mappings
	  }));
	}	  

      // Handle thought logs
      if (data.type === 'thought') {
        setThoughtLogs(prev => [...prev, data.content]);
        return;
      }

      // Handle chunks
if (data.chunk !== undefined) {
  let chunkText = data.chunk;
  
  // Update result with new chunk
  setResult(prev => {
    const updatedAnswer = ((prev?.answer) || '') + chunkText;
    return {
      ...(prev || {}),
      question,
      answer: updatedAnswer
    };
  });

  // Also check for section headers to update loadingSections
  if (chunkText.includes('1. DIRECT ANSWER:')) {
    setCurrentSection('DIRECT ANSWER');
    setLoadingSections(prev => [...prev, 'DIRECT ANSWER']);
  }

  // Process sections when we receive certain markers
  const sectionMarkers = [
    'DIRECT ANSWER:',
    'LEGAL BASIS:',
    'KEY REQUIREMENTS:',
    'LIMITATIONS AND EXCEPTIONS:'
  ];

  const hasMarker = sectionMarkers.some(marker => 
    chunkText.includes(marker) || 
    // Also check for partial headers at chunk boundaries
    chunkText.match(new RegExp(`\\d+\\.\\s*${marker.split(':')[0]}$`))
  );

  if (hasMarker) {
    setCurrentSection(sectionMarkers.find(marker => 
      chunkText.includes(marker)
    )?.split(':')[0] || currentSection);

    // Add to loading sections if not already there
    setLoadingSections(prev => {
      const section = sectionMarkers.find(marker => 
        chunkText.includes(marker)
      )?.split(':')[0];
      return section && !prev.includes(section) 
        ? [...prev, section]
        : prev;
    });
  }
}


      // Handle other message types
      if (data.type === 'quote_location') {
        setSelectedScreenshot(prev => ({
          ...prev,
          highlight: data.data?.coordinates,
          confidence: data.data?.confidence,
          found_exact: data.data?.found_exact
        }));
      }

      if (data.sources) {
        setResult(prev => ({
          ...(prev || {}),
          sources: Array.isArray(data.sources) ? data.sources : [],
        }));
      }

      if (data.mermaid_diagram) {
        setResult(prev => ({
          ...(prev || {}),
          mermaid_diagram: data.mermaid_diagram,
        }));
      }

      if (data.stage && data.status) {
        setProgressMessage(data.status === 'in_progress' ? data.message : 'Processing complete.');
      }

      if (data.error) {
        setError(data.error);
        setLoading(false);
      }

      if (data.done) {
        setLoading(false);
        setCurrentSection(null);
        setLoadingSections([]);
        setProgressMessage('');
      }

    } catch (err) {
      setError('Failed to process message');
      setLoading(false);
      setProgressMessage('');
    }
  };

  websocket.onopen = () => {
    clearTimeout(connectionTimeout);
    setConnectionStatus('connected');
    setConnected(true);
    setReconnectAttempts(0);
    setError(null);
    setIsReconnecting(false);
    
    // Start keepalive
    keepaliveInterval = setInterval(() => {
      if (websocket.readyState === WebSocket.OPEN) {
        websocket.send(JSON.stringify({ action: 'keepalive' }));
      }
    }, WS_KEEPALIVE_INTERVAL);
  };

  websocket.onclose = (event) => {
    cleanup();
    setConnected(false);
    setConnectionStatus('disconnected');
    setIsReconnecting(false);

    // Handle reconnection with exponential backoff
    if (!manualCloseRef.current && reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
      const retryDelay = Math.min(RETRY_DELAY * Math.pow(2, reconnectAttempts), 30000);
      setTimeout(() => {
        if (!connected) {
          setReconnectAttempts(prev => prev + 1);
          connectWebSocket();
        }
      }, retryDelay);
    }
  };

  websocket.onerror = (error) => {
    setConnectionStatus('disconnected');
    setError('Connection error occurred');
    cleanup();
    setIsReconnecting(false);
  };

  websocket.onmessage = handleMessage;
  wsRef.current = websocket;

  return cleanup;
};

  // Load History
  useEffect(() => {
    try {
      const savedHistory = localStorage.getItem('legalOracleHistory');
      if (savedHistory) {
        const parsedHistory = JSON.parse(savedHistory);
        if (Array.isArray(parsedHistory)) {
          const validHistory = parsedHistory.filter(
            (item) =>
              typeof item.question === 'string' &&
              typeof item.answer === 'string' &&
              typeof item.timestamp === 'string'
          );
          setHistory(validHistory);
        } else {
          setHistory([]);
        }
      } else {
        setHistory([]);
      }
    } catch (err) {
      setHistory([]);
    }
  }, []);

  // Save History
  useEffect(() => {
    try {
      localStorage.setItem('legalOracleHistory', JSON.stringify(history));
    } catch (err) {
    }
  }, [history]);

  // Action Message Timeout
  useEffect(() => {
    if (actionMessage) {
      const timer = setTimeout(() => setActionMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [actionMessage]);

	useEffect(() => {
	  const isBot = () => {
		return /bot|googlebot|crawler|spider|robot|crawling|inspection|Google-InspectionTool/i.test(navigator.userAgent);
	  };

	  if (isBot()) {
		// For bots, start in a ready state
		setConnected(true);
		setError(null);
		setLoading(false);
		document.body.style.removeProperty('pointer-events');
		document.body.removeAttribute('data-scroll-locked');
	  }
	}, []);


  // Scroll to Top Visibility
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

useEffect(() => {
  const handleOnline = () => {
    if (!connected) {
      setReconnectAttempts(0);
      connectWebSocket();
    }
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && !connected) {
      setReconnectAttempts(0);
      connectWebSocket();
    }
  };

  window.addEventListener('online', handleOnline);
  document.addEventListener('visibilitychange', handleVisibilityChange);

  return () => {
    window.removeEventListener('online', handleOnline);
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}, [connected]);


  // Update Suggestions
  useEffect(() => {
    if (question.trim().length > 0) {
      const filteredSuggestions = history
        .map((item) => item.question)
        .filter((q) => q.toLowerCase().includes(question.toLowerCase()))
        .slice(0, MAX_SUGGESTIONS);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [question, history]);

  // Handle Scroll to Top
  const handleScrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

// Handle Disclaimer Accept
const handleDisclaimerAccept = useCallback(() => {
  const currentTime = new Date().getTime();
  
  try {
    localStorage.setItem('legalOracle_accepted_time', currentTime.toString());
    
    // Verify the save
    const savedTime = localStorage.getItem('legalOracle_accepted_time');
    
    setShowDisclaimer(false);
  } catch (error) {
  }
}, []);


  // Handle Form Submit
const handleSubmit = async (e) => {
  e.preventDefault();
  if (!question.trim() || !connected || isReconnecting) return;
    
  if (searchMode === 'search' && compareMode && selectedJurisdictions.length < 2) {
    setError('Please select at least 2 jurisdictions to compare');
    return;
  }
  

  
  

  // Clear previous state
  setLoading(true);
  setError(null);
  setShowHistory(false);
  setResult(null);
  setCurrentSection(null);
  setLoadingSections([]);
  setProgressMessage(
    searchMode === 'search' 
      ? 'Analysing your question...' 
      : 'Generating concept map...'
  );
  setThoughtLogs([]);

  // Set up response timeout
  const responseTimeout = setTimeout(() => {
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      setLoading(false);
      setProgressMessage('');
      
      // Force WebSocket reconnection
      wsRef.current.close();
      connectWebSocket();
    }
  }, 45000); // 45 second timeout

  try {
    // Verify WebSocket state before sending
    if (wsRef.current?.readyState !== WebSocket.OPEN) {
      throw new Error('WebSocket not connected');
    }

    // Send request with metadata
    wsRef.current.send(
      JSON.stringify({
        action: searchMode === 'search' ? 'query' : 'concept_map',
        body: {
          [searchMode === 'search' ? 'question' : 'concept']: sanitizeInput(question),
          jurisdiction: selectedJurisdictions.join(','),
          metadata: {
            timestamp: new Date().toISOString(),
            client_id: localStorage.getItem('clientId') || 'unknown',
            session_start: sessionStorage.getItem('sessionStart') || 'unknown',
            mode: searchMode
          }
        },
      })
    );

    // Add to history if send successful
    const historyEntry = {
      question: question.trim(),
      timestamp: new Date().toISOString(),
      mode: searchMode // Track the mode in history
    };
    setHistory(prev => [historyEntry, ...prev].slice(0, MAX_HISTORY));

  } catch (error) {
    // Clear timeout if there's an immediate error
    clearTimeout(responseTimeout);
    
    
    if (error.message === 'WebSocket not connected') {
      setConnectionStatus('disconnected');
      connectWebSocket();
    }
    setLoading(false);
    setProgressMessage('');

    // Attempt to recover connection if needed
    if (error.message === 'WebSocket not connected') {
      connectWebSocket();
    }
  }

  // Cleanup function
  return () => {
    clearTimeout(responseTimeout);
  };
};


  // Handle Example Click
  const handleExampleClick = useCallback((exampleQuestion) => {
    setQuestion(exampleQuestion);
    setShowHistory(false);
    setError(null);
    setSuggestions([]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // Handle History Item Click
  const handleHistoryItemClick = useCallback(
    (historyItem) => {
      setResult(historyItem);
      setQuestion('');
      setShowHistory(false);
      setLoading(false);
      setCurrentSection(null);
      setLoadingSections([]);
      setSuggestions([]);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [setResult]
  );

  // Clear History
  const clearHistory = useCallback(() => {
    try {
      setHistory([]);
      localStorage.removeItem('legalOracleHistory');
      setShowHistory(false);
      setActionMessage('History cleared');
    } catch (err) {
      setError('Failed to clear history');
    }
  }, []);

  // Handle New Search
  const handleNewSearch = useCallback(() => {
    setResult(null);
    setQuestion('');
    setError(null);
    setCurrentSection(null);
    setLoadingSections([]);
    setSuggestions([]);
  }, []);

  // Handle Copy
  const handleCopy = useCallback(async () => {
    if (!result) return;

    try {
      await navigator.clipboard.writeText(result.answer);
      setActionMessage('Copied to clipboard');
    } catch (err) {
      setActionMessage('Failed to copy');
    }
  }, [result]);

  // Handle Share
  const handleShare = useCallback(async () => {
    if (!result) return;

    const text = `Q: ${result.question}\nA: ${result.answer}`;

    try {
      if (navigator.share) {
        await navigator.share({ text });
        setActionMessage('Shared successfully');
      } else {
        await navigator.clipboard.writeText(text);
        setActionMessage('Copied to clipboard');
      }
    } catch (err) {
      setActionMessage('Failed to share');
    }
  }, [result]);

  // Toggle Theme
  const toggleTheme = useCallback(() => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
  }, [theme]);

  // Toggle Font Size
const toggleFontSize = useCallback(() => {
  const newSize = fontSize === 'normal' ? 'large' : 'normal';
  setFontSize(newSize);
  localStorage.setItem('fontSize', newSize);
  
  // Update the CSS variable
  document.documentElement.style.setProperty(
    '--base-font-size',
    newSize === 'large' ? '1.125rem' : '1rem'
  );
}, [fontSize]);

  // Handle Suggestion Click
  const handleSuggestionClick = useCallback((suggestion) => {
    setQuestion(suggestion);
    setSuggestions([]);
  }, []);

  // Toggle Collapse
  const toggleCollapse = useCallback((sectionTitle) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [sectionTitle]: !prev[sectionTitle],
    }));
  }, []);
  
// SEODescriptionBoxes Component

const SEODescriptionBoxes = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* About Box */}
      <div className="bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-md relative">
        <div className="absolute top-0 left-0 bottom-0 w-16 bg-blue-50 dark:bg-blue-900/20 rounded-l-2xl" />
        <div className="absolute -top-2 -right-2">
          <span className="px-2 py-0.5 text-xs font-medium bg-blue-600 text-white rounded-full">
            FREE BETA
          </span>
        </div>
        <div className="flex gap-4 relative">
          <div className="flex-shrink-0 p-3">
            <BookOpen className="h-6 w-6 text-blue-600 dark:text-blue-400" />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-3">
              About Legal Oracle
            </h2>
            <p className="text-gray-600 dark:text-gray-400 leading-relaxed">
              Legal Oracle is a free beta AI-assisted research tool designed to help explore Australian legislation 
              and regulations. It aims to assist citizens, business owners, and legal professionals in 
              finding relevant legislative information, while emphasizing the importance of verification 
              through official sources.
            </p>
          </div>
        </div>
      </div>

      {/* Research Features Box */}
      <div className="bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-md relative">
        <div className="absolute top-0 left-0 bottom-0 w-16 bg-purple-50 dark:bg-purple-900/20 rounded-l-2xl" />
        <div className="flex gap-4 relative">
          <div className="flex-shrink-0 p-3">
            <Search className="h-6 w-6 text-purple-600 dark:text-purple-400" />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-3">
              Research Features
            </h2>
            <ul className="space-y-2">
              {[
                "Assists in finding relevant Acts and Regulations",
                "Attempts to track legislative updates",
                "Aims to provide clear explanations with citations",
                "References to source legislation where available",
                "Experimental support for SA, VIC and Commonwealth jurisdictions",
                "SA Case Law & Judgements coming soon!"
              ].map((feature, index) => (
                <li key={index} className="flex items-start gap-2">
                  <div className="flex-shrink-0 mt-2">
                    <div className="h-2 w-2 bg-purple-400 dark:bg-purple-400 rounded-full" />
                  </div>
                  <span className={`text-gray-600 dark:text-gray-400 ${
                    index === 5 ? 'text-blue-600 dark:text-blue-400 font-medium' : ''
                  }`}>
                    {feature}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Concept Map Box */}
      <div className="bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-md relative">
        <div className="absolute top-0 left-0 bottom-0 w-16 bg-green-50 dark:bg-green-900/20 rounded-l-2xl" />
        <div className="absolute -top-2 -right-2">
          <span className="px-2 py-0.5 text-xs font-medium bg-blue-600 text-white rounded-full">
            NEW
          </span>
        </div>
        <div className="flex gap-4 relative">
          <div className="flex-shrink-0 p-3">
            <Network className="h-6 w-6 text-green-600 dark:text-green-400" />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-3">
              Concept Map Visualisation
            </h2>
            <p className="text-gray-600 dark:text-gray-400 leading-relaxed mb-4">
              Explore legal concepts through interactive visual maps that show relationships between different 
              pieces of legislation, regulations, and legal principles.
            </p>
            <div className="flex flex-wrap gap-2 mt-2 text-sm">
              <span className="text-gray-600 dark:text-gray-400">Visual relationships</span>
              <span className="text-gray-300 dark:text-gray-600 hidden sm:inline">•</span>
              <span className="text-gray-600 dark:text-gray-400">Interactive zoom</span>
              <span className="text-gray-300 dark:text-gray-600 hidden sm:inline">•</span>
              <span className="text-gray-600 dark:text-gray-400">Cross-jurisdictional</span>
            </div>
          </div>
        </div>
      </div>

      {/* Accuracy Box */}
      <div className="bg-white dark:bg-gray-800 rounded-2xl p-6 shadow-md relative">
        <div className="absolute top-0 left-0 bottom-0 w-16 bg-blue-50 dark:bg-blue-900/20 rounded-l-2xl" />
        <div className="flex gap-4 relative">
          <div className="flex-shrink-0 p-3">
            <ShieldCheck className="h-6 w-6 text-blue-600 dark:text-blue-400" />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-3">
              Accuracy & Transparency
            </h2>
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <FileCheck className="h-5 w-5 text-green-500" />
                <span className="text-gray-600 dark:text-gray-400">
                  Fully Formed Facts: Complete context preservation
                </span>
              </div>
              <div className="flex items-center gap-2">
                <Database className="h-5 w-5 text-blue-500" />
                <span className="text-gray-600 dark:text-gray-400">
                  Dual Index Retrieval for comprehensive coverage
                </span>
              </div>
              <div className="flex items-center gap-2">
                <Scale className="h-5 w-5 text-purple-500" />
                <span className="text-gray-600 dark:text-gray-400">
                  Bias and QA validation checks
                </span>
              </div>
            </div>
            <p className="text-blue-600 dark:text-blue-400 text-sm mt-4">
              Our commitment to accuracy includes multiple validation layers, source verification, 
              and transparent citation of all legislative references.
            </p>
          </div>
        </div>
      </div>
    </div>

  );
};







  
const JurisdictionCardSelect = ({
  compareMode,
  selectedJurisdictions,
  onChange,
}) => {
  const [activeTooltip, setActiveTooltip] = useState(null);

  const getTooltipContent = (type) => {
    if (type === 'Acts') {
      return 'Acts are laws passed by Parliament';
    } else if (type === 'Regs') {
      return 'Regulations are detailed rules under Acts';
    }
    return '';
  };

const Badge = ({ count, type, index }) => (
  <div className="relative">
    <button
      type="button"
      className={`text-xs ${
        type === 'Acts'
          ? 'bg-blue-100 dark:bg-blue-900/50 text-blue-800 dark:text-blue-200'
          : 'bg-green-100 dark:bg-green-900/50 text-green-800 dark:text-green-200'
      } px-1.5 sm:px-2 py-0.5 rounded-full hover:ring-2 hover:ring-blue-300 dark:hover:ring-blue-700 transition-all whitespace-nowrap`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        const tooltipId = `${type}-${count}`;
        setActiveTooltip((prev) => (prev === tooltipId ? null : tooltipId));
      }}
      aria-label={`${count} ${type}: ${getTooltipContent(type)}`}
    >
      <span className="hidden sm:inline">{count.toLocaleString()} {type}</span>
      <span className="sm:hidden">
        {type === 'Acts' ? 'A:' : 'R:'} {count.toLocaleString()}
      </span>
    </button>

    {activeTooltip === `${type}-${count}` && (
      <div
        className={`absolute z-50 bottom-full mb-2 px-3 py-2 bg-gray-900 text-white text-sm rounded-lg shadow-lg whitespace-nowrap ${
          index === 0 ? 'left-0' : 'left-1/2 transform -translate-x-1/2'
        }`}
        role="tooltip"
      >
        {getTooltipContent(type)}
        <div
          className={`absolute -bottom-1 ${
            index === 0 ? 'left-4' : 'left-1/2 transform -translate-x-1/2'
          } w-2 h-2 bg-gray-900 rotate-45`}
        />
      </div>
    )}
  </div>
);


  const handleCardClick = (code, available) => {
    if (!available) return;

    if (!compareMode) {
      onChange([code]);
    } else {
      if (selectedJurisdictions.includes(code)) {
        onChange(selectedJurisdictions.filter(j => j !== code));
      } else {
        onChange([...selectedJurisdictions, code]);
      }
    }
  };

  const getResponsiveName = (jurisdiction) => {
    switch (jurisdiction.code) {
      case 'CWTH':
        return {
          default: <span className="hidden md:inline">Commonwealth (Federal)</span>,
          medium: <span className="hidden sm:inline md:hidden">Commonwealth</span>,
          small: <span className="inline sm:hidden">CTH</span>
        };
      case 'SA':
        return {
          default: <span className="hidden md:inline">South Australia</span>,
          medium: <span className="hidden sm:inline md:hidden">South Australia</span>,
          small: <span className="inline sm:hidden">SA</span>
        };
      case 'VIC':
        return {
          default: <span className="hidden md:inline">Victoria</span>,
          medium: <span className="hidden sm:inline md:hidden">Victoria</span>,
          small: <span className="inline sm:hidden">VIC</span>
        };
      default:
        return {
          default: <span className="hidden md:inline">{jurisdiction.name}</span>,
          medium: <span className="hidden sm:inline md:hidden">{jurisdiction.name}</span>,
          small: <span className="inline sm:hidden">{jurisdiction.code}</span>
        };
    }
  };

  return (
    <div className="border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden bg-white dark:bg-gray-900">
      <div className="px-3 py-2 border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 flex justify-between items-center">
        <h2 className="text-sm font-medium text-gray-700 dark:text-gray-300">
          {compareMode ? "Select jurisdictions to compare" : "Select jurisdiction"}
        </h2>
        
        <div className="flex items-center gap-2">
          <div className="hidden sm:flex items-center gap-1">
            <span className="text-sm text-gray-600 dark:text-gray-400">Compare</span>
            <span className="text-yellow-500 font-medium text-sm">(Experimental!)</span>
          </div>
          <Tooltip.Provider delayDuration={300}>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <div className="flex items-center gap-2">
                  {compareMode && (
                    <AlertCircle className="h-4 w-4 text-yellow-500" />
                  )}
                  <div className="relative inline-block w-12 h-6 align-middle select-none">
                    <input
                      type="checkbox"
                      id="compare-toggle"
                      role="switch"
                      aria-checked={compareMode}
                      checked={compareMode}
                      onChange={(e) => setCompareMode(e.target.checked)}
                      className="absolute block w-6 h-6 bg-white border-2 border-gray-300 rounded-full appearance-none cursor-pointer
                        focus:outline-none focus:ring-2 focus:ring-blue-500 
                        peer transition-all"
                      style={{
                        left: 0,
                        top: 0,
                        transform: compareMode ? 'translateX(100%)' : 'translateX(0)',
                      }}
                    />
                    <label
                      htmlFor="compare-toggle"
                      className="block h-6 rounded-full bg-gray-300 dark:bg-gray-600
                        cursor-pointer peer-checked:bg-blue-600"
                    />
                  </div>
                </div>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content
                  side="top"
                  align="end"
                  className="bg-gray-800 text-white text-sm px-3 py-2 rounded-lg shadow-lg max-w-xs"
                >
                  <p className="font-medium mb-1">Compare Jurisdictions</p>
                  <p className="text-gray-200 text-xs">
                    Enable to compare legislation across multiple jurisdictions
                  </p>
                  <Tooltip.Arrow className="fill-gray-800" />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-2 p-2">
        {JURISDICTIONS.map((jurisdiction, index) => {
          const isDisabled = !jurisdiction.available;
          const isSelected = selectedJurisdictions.includes(jurisdiction.code);
          const responsiveName = getResponsiveName(jurisdiction);

          return (
            <button
              key={jurisdiction.code}
              onClick={() => handleCardClick(jurisdiction.code, !isDisabled)}
              disabled={isDisabled}
              className={`relative p-2 rounded-lg border transition-all duration-200
                ${
                  isSelected
                    ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
                    : isDisabled
                    ? 'border-yellow-500 bg-yellow-50 dark:bg-yellow-900/20 cursor-not-allowed'
                    : 'border-gray-200 dark:border-gray-700 hover:border-blue-300 dark:hover:border-blue-700'
                }
              `}
            >
              <div className="flex flex-col gap-1">
                <div className="flex items-start justify-between">
                  <h3
                    className={`text-sm font-medium ${
                      isSelected
                        ? 'text-blue-700 dark:text-blue-400'
                        : 'text-gray-700 dark:text-gray-200'
                    }`}
                  >
                    {responsiveName.default}
                    {responsiveName.medium}
                    {responsiveName.small}
                  </h3>
                  {isSelected && (
                    <CheckCircle className="h-4 w-4 text-blue-500 ml-1 flex-shrink-0" />
                  )}
                </div>

                {jurisdiction.available && (
                  <div className="flex flex-wrap items-center gap-1">
                    {jurisdiction.acts > 0 && (
                      <Badge count={jurisdiction.acts} type="Acts" index={index} />
                    )}
                    {jurisdiction.regulations > 0 && (
                      <Badge count={jurisdiction.regulations} type="Regs" index={index} />
                    )}
                  </div>
                )}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

JurisdictionCardSelect.propTypes = {
  compareMode: PropTypes.bool.isRequired,
  selectedJurisdictions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};
  // Continue with the return statement
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <FontSizeContext.Provider value={{ fontSize, toggleFontSize }}>
        <div>
<TopNavigation 
  setShowAbout={setShowAbout}
  setShowDisclaimer={setShowDisclaimer}
  setShowSafety={setShowSafety}
/>
          <div
            className={`min-h-screen bg-gradient-to-b from-white to-gray-100 dark:from-gray-900 dark:to-gray-800 text-gray-900 dark:text-gray-100 p-4 md:p-6 transition-colors duration-500 ${fontSize}`}
          >
            <a href="#main-content" className="sr-only focus:not-sr-only">
              Skip to main content
            </a>

            <ErrorBoundary>
              <Suspense fallback={<div>Loading...</div>}>
                {showDisclaimer && (
                  <DisclaimerModal isOpen={showDisclaimer} onClose={handleDisclaimerAccept} />
                )}
                {showAbout && (
                  <AboutModal
                    isOpen={showAbout}
                    onClose={() => setShowAbout(false)}
                    selectedJurisdictionName={selectedJurisdictionName}
                    selectedJurisdictionAvailable={selectedJurisdictionAvailable}
                    selectedJurisdictionCode={selectedJurisdiction}
                  />
                )}
				{showSafety && (
				  <Dialog.Root open={showSafety} onOpenChange={() => setShowSafety(false)}>
					<Dialog.Portal>
					  <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" />
					  <Dialog.Content 
						className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[600px] 
						transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white dark:bg-gray-900 
						border border-gray-300 dark:border-gray-700 p-6 shadow-lg z-50 overflow-y-auto 
						focus:outline-none"
					  >
						<SystemProtections />
						<div className="mt-6 flex justify-end">
						  <button
							onClick={() => setShowSafety(false)}
							className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
							transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
						  >
							Close
						  </button>
						</div>
					  </Dialog.Content>
					</Dialog.Portal>
				  </Dialog.Root>
				)}				
				
              </Suspense>
            </ErrorBoundary>

			{showScrollToTop && (
			  <button
				onClick={handleScrollToTop}
				className="fixed bottom-24 right-6 p-3 rounded-full bg-blue-600 text-white shadow-lg hover:bg-blue-700 transition-colors z-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
				aria-label="Scroll to top"
			  >
				<ArrowUpCircle className="h-6 w-6" aria-hidden="true" />
			  </button>
			)}
			
			<ConnectionStatus status={connectionStatus} />

            <div className="max-w-4xl mx-auto" id="main-content">
              <header className="text-center mb-8">
                <div className="flex justify-end gap-2">

                </div>
  <h1 className="text-3xl md:text-4xl font-bold mb-2 flex items-center justify-center gap-2">
    <BookOpen className="h-8 w-8" aria-hidden="true" />
    Legal Oracle
    <span className="bg-blue-600 text-white text-sm px-2 py-0.5 rounded-lg">
      BETA
    </span>
  </h1>
					<p className="text-gray-700 dark:text-gray-400 mb-1">
					  {selectedJurisdictionAvailable
						? compareMode
						  ? `Compare ${selectedJurisdictions.map(code => 
							  JURISDICTIONS.find(j => j.code === code)?.name
							).join(' and ')} legislation`
						  : `Ask questions about South Australia, Victoria, and Federal legislation`
						: `Select a jurisdiction to get started`}
					</p>
				  <StatsDisplay selectedJurisdictions={selectedJurisdictions} />
				</header>

              {/* Search Form */}
<form onSubmit={handleSubmit} aria-label="Search form">
  <div className="space-y-4">
    {/* Tabs */}
<div className="mb-6">
  <nav className="flex gap-1" aria-label="Search options">
    <button
      type="button"
      onClick={() => setSearchMode('search')}
      className={`relative px-6 py-3 rounded-t-lg transition-colors flex items-center gap-2
        ${searchMode === 'search' 
          ? 'bg-white dark:bg-gray-800 text-blue-600 dark:text-blue-400 border border-gray-200 dark:border-gray-700 border-b-0' 
          : 'bg-gray-50 dark:bg-gray-900 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'}`}
    >
      <Search className="h-4 w-4" />
      <span className="font-medium">Search Legislation</span>
    </button>
    
    <button
      type="button"
      onClick={() => setSearchMode('concept')}
      className={`relative px-6 py-3 rounded-t-lg transition-colors flex items-center gap-2
        ${searchMode === 'concept'
          ? 'bg-white dark:bg-gray-800 text-blue-600 dark:text-blue-400 border border-gray-200 dark:border-gray-700 border-b-0' 
          : 'bg-gray-50 dark:bg-gray-900 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'}`}
    >
      <Network className="h-4 w-4" />
      <span className="font-medium">Concept Map</span>
      <span className="absolute -top-2 -right-2 px-2 py-0.5 text-xs font-medium bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-400 rounded-full border border-blue-200 dark:border-blue-800 animate-bounce">
        New
      </span>
    </button>
  </nav>
  <div className="border-b border-gray-200 dark:border-gray-700" />
</div>

    {searchMode === 'search' ? (
      <>
        {/* Search Mode Content */}
        <div className="relative">
          <label htmlFor="question-input" className="sr-only">
            Ask a question
          </label>
          <input
            type="text"
            id="question-input"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder={
              connected
                ? selectedJurisdictionAvailable
                  ? 'Ask a question...'
                  : 'Please select an available jurisdiction'
                : 'Connecting...'
            }
            className="w-full px-4 py-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            disabled={loading || !connected || !selectedJurisdictionAvailable}
            autoComplete="off"
            aria-autocomplete="list"
            aria-expanded={suggestions.length > 0}
            aria-owns="suggestions-list"
          />
          <div className="absolute right-2 top-2 flex gap-2">
            {history.length > 0 && !loading && (
              <Tooltip.Provider>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <button
                      type="button"
                      onClick={() => setShowHistory(!showHistory)}
                      className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                      aria-label="View History"
                    >
                      <Clock className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </Tooltip.Trigger>
                  <Tooltip.Content
                    side="left"
                    className="bg-gray-800 text-gray-200 text-xs px-2 py-1 rounded shadow-lg"
                  >
                    View History
                  </Tooltip.Content>
                </Tooltip.Root>
              </Tooltip.Provider>
            )}
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <button
                    type="submit"
                    disabled={loading || !connected || !question.trim() || !selectedJurisdictionAvailable}
                    className="p-2 rounded-md bg-blue-600 hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                    aria-label="Search"
                  >
                    {loading ? (
                      <Scale className="h-5 w-5 animate-spin" aria-hidden="true" />
                    ) : (
                      <Search className="h-5 w-5" aria-hidden="true" />
                    )}
                  </button>
                </Tooltip.Trigger>
                <Tooltip.Content
                  side="left"
                  className="bg-gray-800 text-gray-200 text-xs px-2 py-1 rounded shadow-lg"
                >
                  Search
                </Tooltip.Content>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
        </div>

        {/* Jurisdiction Selection - Only for search mode */}
        {!loading && (
          <div className="transition-opacity duration-300">
            <JurisdictionCardSelect
              compareMode={compareMode}
              selectedJurisdictions={selectedJurisdictions}
              onChange={setSelectedJurisdictions}
              setCompareMode={setCompareMode}
            />
          </div>
        )}
      </>
    ) : (
      <>
        {/* Concept Map Mode Content */}
        <div className="relative">
          <label htmlFor="concept-input" className="sr-only">
            Enter a legal concept
          </label>
          <input
            type="text"
            id="concept-input"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Enter a legal concept to map..."
            className="w-full px-4 py-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            disabled={loading || !connected}
            autoComplete="off"
          />
          <div className="absolute right-2 top-2">
            <Tooltip.Provider>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <button
                    type="submit"
                    disabled={loading || !connected || !question.trim()}
                    className="p-2 rounded-md bg-blue-600 hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                    aria-label="Generate Map"
                  >
                    {loading ? (
                      <Scale className="h-5 w-5 animate-spin" aria-hidden="true" />
                    ) : (
                      <Network className="h-5 w-5" aria-hidden="true" />
                    )}
                  </button>
                </Tooltip.Trigger>
                <Tooltip.Content
                  side="left"
                  className="bg-gray-800 text-gray-200 text-xs px-2 py-1 rounded shadow-lg"
                >
                  Generate Map
                </Tooltip.Content>
              </Tooltip.Root>
            </Tooltip.Provider>
          </div>
        </div>


{/* Popular Concepts */}
{!result && !loading && (
  <div className="mt-4">
    <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">Popular concepts:</p>
    <div className="flex flex-wrap gap-2 mb-8">
      {[
        'Child Protection',
        'Public Sector Integrity',
        'Criminal Law',
        'Environmental Protection',
        'Consumer Rights',
        'Employment Law',
        'Privacy & Data Protection',
        'Family Law',
        'Property Law',
      ].map((concept) => (
        <button
          key={concept}
          type="button"
          onClick={() => handleExampleClick(concept)}
          className="px-3 py-1.5 text-sm bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 
            rounded-full text-gray-700 dark:text-gray-300 transition-colors border border-gray-200 dark:border-gray-700"
        >
          {concept}
        </button>
      ))}
    </div>
  </div>
)}

      </>
    )}
  </div>
</form>


			{/* Error Message */}
			{error && !isBot && (
			  <div
				className="bg-red-100 dark:bg-red-900/50 border border-red-300 dark:border-red-700 rounded-lg p-4 mb-6"
				role="alert"
			  >
				<p className="text-red-700 dark:text-red-200">{error}</p>
			  </div>
			)}


              {/* Action Message */}
              {actionMessage && (
                <div
                  className="fixed top-4 right-4 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg animate-fadeIn"
                  role="alert"
                  aria-live="assertive"
                >
                  {actionMessage}
                </div>
              )}

              {/* History */}
              {showHistory && (
                <>
                  {history.length > 0 ? (
                    <div className="mb-8 bg-gray-100 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg overflow-hidden animate-fadeIn">
                      <div className="p-4 border-b border-gray-300 dark:border-gray-700 flex justify-between items-center">
                        <h3 className="font-semibold">Previous Questions</h3>
                        <button
                          onClick={clearHistory}
                          className="text-sm text-red-600 dark:text-red-400 hover:text-red-500 dark:hover:text-red-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          Clear History
                        </button>
                      </div>
                      <div className="divide-y divide-gray-300 dark:divide-gray-700">
                        {history.map((item, index) => (
                          <button
                            key={index}
                            onClick={() => handleHistoryItemClick(item)}
                            className="w-full p-4 text-left hover:bg-gray-200 dark:hover:bg-gray-700/50 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                          >
                            <p className="text-gray-800 dark:text-gray-300 font-medium">
                              {item.question}
                            </p>
                            <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                              {new Date(item.timestamp).toLocaleString()}
                            </p>
                          </button>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <p className="text-center text-gray-600 dark:text-gray-400 mt-4">
                      No history available yet.
                    </p>
                  )}
                </>
              )}

              {/* Loading State */}
				{loading && !result?.answer?.includes('1. DIRECT ANSWER') && (
				  <div 
					className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-6 shadow-lg text-center"
					role="status" 
					aria-live="polite"
				  >
					<div className="flex flex-col items-center gap-4">
					  <Scale className="h-12 w-12 animate-spin text-gray-900 dark:text-gray-100" aria-hidden="true" />
					  
					  <div className="space-y-2">
						<div className="text-lg font-medium animate-pulse">
						  <DynamicProgressMessage message={progressMessage} />
						</div>
						<p className="text-sm text-gray-600 dark:text-gray-400">
						  This may take a few moments
						</p>
					  </div>

					  <div className="w-full max-w-md mx-auto mt-2">
						<div className="h-1.5 bg-gray-100 dark:bg-gray-700 rounded-full overflow-hidden">
						  <div className="h-full bg-gray-900 dark:bg-gray-100 rounded-full animate-progress" 
							style={{
							  width: '100%',
							  animation: 'progress 2s ease-in-out infinite'
							}}
						  />
						</div>
					  </div>
					</div>
				  </div>
				)}

				{/* Results */}
				{result && (
				  <div className="space-y-6 animate-fadeIn">
					<div className="flex justify-between items-center">
					  <button
						onClick={handleNewSearch}
						className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
					  >
						<ArrowLeft className="h-4 w-4" aria-hidden="true" />
						New Search
					  </button>
					  {!result.type === 'concept_map' && (
						<div className="flex gap-2">
						  <Tooltip.Provider>
							<Tooltip.Root>
							  <Tooltip.Trigger asChild>
								<button
								  onClick={handleCopy}
								  className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
								  title="Copy to clipboard"
								  aria-label="Copy to clipboard"
								>
								  <Copy className="h-4 w-4" aria-hidden="true" />
								</button>
							  </Tooltip.Trigger>
							  <Tooltip.Content
								side="top"
								className="bg-gray-800 text-gray-200 text-xs px-2 py-1 rounded shadow-lg"
							  >
								Copy to Clipboard
							  </Tooltip.Content>
							</Tooltip.Root>
						  </Tooltip.Provider>
						  <Tooltip.Provider>
							<Tooltip.Root>
							  <Tooltip.Trigger asChild>
								<button
								  onClick={handleShare}
								  className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
								  title="Share"
								  aria-label="Share"
								>
								  <Share2 className="h-4 w-4" aria-hidden="true" />
								</button>
							  </Tooltip.Trigger>
							  <Tooltip.Content
								side="top"
								className="bg-gray-800 text-gray-200 text-xs px-2 py-1 rounded shadow-lg"
							  >
								Share
							  </Tooltip.Content>
							</Tooltip.Root>
						  </Tooltip.Provider>
						</div>
					  )}
					</div>



{result.type === 'concept_map' ? (
  <>
    <div className="space-y-6">
      <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 p-4">
        <ConceptMap d2Code={result.d2Code} />
      </div>
    </div>
    <div className="fixed bottom-0 left-0 right-0 z-40 bg-white dark:bg-gray-900 border-t border-gray-200 dark:border-gray-700 shadow-lg">
      <div className="max-w-4xl mx-auto px-4 py-3">
        <FeedbackPanel 
          type="concept_map"
          queryText={result.concept_query}
          responseText={JSON.stringify(result.d2Code)}
          wsRef={wsRef}
        />
      </div>
    </div>
    <div className="h-24" /> {/* Spacer to prevent content from being hidden */}
  </>
) : (
  <>
    <div className="space-y-6">
      <FormattedAnswer
        answer={result.answer}
        loadingSections={loadingSections}
        result={result}
        sectionsConfig={sectionsConfig}
        collapsedSections={collapsedSections}
        onToggleCollapse={toggleCollapse}
        wsRef={wsRef}
        selectedScreenshot={selectedScreenshot}
        setSelectedScreenshot={setSelectedScreenshot}
        thoughtLogs={thoughtLogs}
      />
    </div>
    <div className="fixed bottom-0 left-0 right-0 z-40 bg-white dark:bg-gray-900 border-t border-gray-200 dark:border-gray-700 shadow-lg">
      <div className="max-w-4xl mx-auto px-4 py-3">
        <FeedbackPanel 
          type="query"
          queryText={result.question}
          responseText={result.answer}
          wsRef={wsRef}
        />
      </div>
    </div>
    <div className="h-24" /> {/* Spacer to prevent content from being hidden */}
  </>
)}




					{loading && (
					  <div className="text-center py-4" role="status" aria-live="polite">
						<Loader2 className="h-6 w-6 animate-spin mx-auto mb-2" aria-hidden="true" />
						<p className="text-gray-700 dark:text-gray-400">
						  {result.type === 'concept_map' ? 'Generating concept map...' : 'Generating response...'}
						</p>
					  </div>
					)}

					<Footer 
					  setShowDisclaimer={setShowDisclaimer} 
					  setShowAbout={setShowAbout}
					  setShowSafety={setShowSafety}
					/>
				  </div>
				)}


{/* Results or Initial State */}
{!result && !loading && !showHistory && (
  <div className="mb-8 space-y-8">
    {searchMode === 'search' && (
      <ExampleQuestionsGrid 
        onQuestionClick={handleExampleClick} 
        selectedJurisdiction={selectedJurisdiction}
      />
    )}
    <SEODescriptionBoxes />
    <Footer 
      setShowDisclaimer={setShowDisclaimer} 
      setShowAbout={setShowAbout}
      setShowSafety={setShowSafety} 
    />
  </div>
)}
            </div>
          </div>
        </div>
      </FontSizeContext.Provider>
    </ThemeContext.Provider>
  );
}
